import React from 'react';
import { Toolbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Divider, Typography } from '@mui/material';
import slaughterTimeDifference from '../../../Helpers/dateDifference.js';
import { maturityChartSections } from '../../../constants/index.js';

const AttributeMadurationChart = ({ value,data, tt }) => {
  const { t } = useTranslation();

  const getCategoryBackgroundColor = (categoryKey) => {
    switch (categoryKey) {
      case 'M':
        return '#d6fabb';
      case 'M+':
        return '#b3e88b';
      case 'M++':
        return '#90d45d';
      case 'M+++':
        return '#6fbf34';
      default:
        return '#ffffff';
    }
  };

  return (
    <>
      <Divider />
      <Typography color='primary.main' variant='h3'style={{ marginBottom: '2px' }}>
          {tt(`qrTemplate.${data.data.label}`)}
          
        </Typography>
      <div style={{ display: 'flex'}}>
        {maturityChartSections.map((section) => {
          const borderColor =
            slaughterTimeDifference(value?.value) >= section.rangeStart &&
            slaughterTimeDifference(value?.value) <= section.rangeEnd
              ? '4px solid #000000'
              : '1px solid #000000';

          const daysCaption =
            section.rangeStart === 22
              ? `${section.rangeStart} ${t('qrProduktoTrupo.oMas')}`
              : `${section.rangeStart}-${section.rangeEnd} ${t(
                  'qrProduktoTrupo.dias'
                )}`;

          return (
            <Toolbar
              key={section.value}
              style={{
                width: '66px',
                height: '55px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#000000',
                fontWeight: 'normal',
                margin: '0 0 0 0',
                padding: '0 0 0 0',
                fontSize: '1rem',
                textShadow: 'none',
                background: getCategoryBackgroundColor(section.value),
                boxSizing: 'border-box',
                border: borderColor,
              }}>
              <Typography style={{ fontWeight: '1000' }}>
                {section.value}
              </Typography>
              <Typography
                variant="caption"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '0.6rem',
                }}>
                {daysCaption}
              </Typography>
            </Toolbar>
          );
        })}
      </div>
    </>
  );
};

export { AttributeMadurationChart, maturityChartSections };
