import { Card, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Cell, Legend, Pie, PieChart } from 'recharts'
import { StyleBoxLegend } from '../../../Components/FileUploader/Cards/CardDetailedInfo.styles';
import { useTranslation } from 'react-i18next';

export const PieChartTop5 = (props) => {
    const { type, qrLogsData, qrLogsTop5, setLogsTop5 } = props
    const { t } = useTranslation();

    useEffect(() => {
        if(!(qrLogsTop5.length > 0)) {
            const unknownName = t(`qrMetrics.PieChart.unknown`)
            const top5Data = qrLogsData.reduce((acc, log) => {
                const data = 
                    type === 'countries' ? (log.qr_log.country_name ? log.qr_log.country_name : unknownName)
                    : type === 'devices' && (log.user_agent ? log.user_agent : unknownName);
                acc[data] = (acc[data] || 0) + 1;
                return acc;
            }, {});
            const sortedData = Object.entries(top5Data)
                .sort((a, b) => b[1] - a[1]);
    
            const top5 = sortedData.slice(0, 5).map(([race, amount]) => ({ race, amount }));
            const othersClicks = sortedData.slice(5).reduce((acc, [, clicks]) => acc + clicks, 0);
    
            if (othersClicks > 0) {
                top5.push({ race: "Others", amount: othersClicks });
            }
    
            setLogsTop5(top5)
        }
    } ,[type, qrLogsData, qrLogsTop5, setLogsTop5])

    const COLORS = ['#4B8B5A', '#76CC81', '#D0C63B', '#C76A6A', '#FFA589', '#EAB8E4'];
    const renderLegend = (props) => {
        const { payload } = props;

        return (
            <Grid container direction="column" alignItems={'left'} spacing={2} style={{ overflowX: 'auto' }}>
                {payload.map((entry, index) => (
                    <Grid key={index} item>
                        <Grid container direction="row">
                            <Grid item className="align-self-center">
                                <StyleBoxLegend color={COLORS[index % COLORS.length]} />
                            </Grid>
                            <Grid item className="ml-1">
                                <Typography variant="buttom" className="font-weight-medium" noWrap>
                                    {entry.payload.amount}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography className="ml-2" variant="caption" noWrap>
                            {entry.payload.race}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <Card className="ml-1" style={{ paddingBottom: "10px"}}>
            <Grid item xs={12} className="d-flex flex-column pt-2 pl-2 pr-2">
                <Grid item className="align-self-start">
                    <Typography noWrap>{t(`qrMetrics.PieChart.top5${type}`)}</Typography>
                </Grid>
                <Grid item height={'auto'}>
                    <PieChart width={300} height={qrLogsTop5.length * 50 + 300}> 
                        <Pie
                            data={qrLogsTop5}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={80}
                            dataKey="amount"
                            label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
                        >
                        {qrLogsTop5.map((en, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                        </Pie>
                        <Legend content={renderLegend} wrapperStyle={{width: 'auto'}}/>
                    </PieChart>
                </Grid>
            </Grid>
        </Card>
    )
}
