import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { ApiFetch } from "../../Utils/ApiFetch"
import useGlobalContext from "../../Hooks/useGlobalContext";
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Button, IconButton,  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/pro-light-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons';
import { DataGrid } from '@mui/x-data-grid';
import LoadSuppliers from "./LoadSupplier";
import ModalConfirmOrDecline from "../../Components/Feature/ModalConfirmOrDecline";
import { toast, ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { HOME } from '../../../src/constants/paths';
import { constPaginationDataGrid } from "../../constants/paginationDataGrid";

const Suppliers = () => {

  const globalContext = useGlobalContext();
  const { pageSizeDGs, setPageSizeDGs } = useGlobalContext();
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [supplier, setSupplier] = useState({});
  const [editSupplier, setEditSupplier] = useState(false);
  const [deleteSupplier, setDeleteSupplier] = useState(false);
  const [loadSupplier, setLoadSupplier] = useState(false);
  const [menuActionSelectedRow, setMenuActionSelectedRow] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const history = useHistory();

  const loadRows = () => {
    ApiFetch(
      `autocomplete/supplier`,
      globalContext,
      true,
      async (result) => {
        let resJson = await result.json();
        setRows(resJson);
      },
      async (error) => {
        console.error('Error:', error.message);
        launchToast(t('suppliers.toastMessages.getAllError') )
      },
      {
        method: 'GET',
      }
    );
  }

  useEffect( ()=>{
    globalContext.selectedBox.subtype != 31 && history.push( HOME )
  }, [globalContext] );

  useEffect(() => {
    loadRows()
  }, []);

  const optionsButton = (row) => {
    return (
    <IconButton
      aria-label="settings"
      onClick={(event) => rowMenuActionshandleClick(event,row)}
    >
      <MoreVertIcon />
    </IconButton>
    )
  }

  const columns = [
    { field: 'options', headerName: '', width: 5, renderCell: (params) => optionsButton(params)  },
    { field: 'id', headerName: 'ID', width: 90  },
    {
      field: 'value',
      headerName: t('suppliers.dataGrid.nameColumn'),
      width: 150
    }
  ];

  const rowMenuActionshandleClick = (event, row) => {
    setMenuActionSelectedRow(row);
    setAnchorEl({top:event.clientY, left:event.clientX});
  };

  const handleEditSupplier = (item) => {
    setEditSupplier(true)
    setSupplier(item)
    setAnchorEl(null);
  }

  const handleModalDeleteSupplier = (item) => {
    setDeleteSupplier(item)
    setAnchorEl(null);
  }

  const handleDeleteSupplier = (confirm,item) => {

    if(confirm){
      ApiFetch(
        `autocomplete/${item.id}`,
        globalContext,
        true,
        (result) => {
          setDeleteSupplier(false)
          loadRows()
          launchToast( t('suppliers.toastMessages.delete'))
        },
        (error) => {
          console.error('Error:', error.message);
          launchToast(t('suppliers.toastMessages.deleteError'))
        },
        {
          method: 'DELETE',
        }
      );
    }

  }

  const launchToast = (text) => {
    toast.success(text, {
      className: 'toast-message-body',
      progressClassName: 'toast-message-progress',
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

	const DataTable = ({ rows, selectedRows, onSelectionModelChange }) => {
		return (
      <Box sx={{ height: 420, width: '100%' }}>
        <DataGrid
            className="data-grid-custom-pagination"
            rows={rows}
            columns={ columns }
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            rowCount={rows.length}
            onPageChange={(params) => console.log(params)}
            componentsProps={{
              pagination: {
                label: t('pagination.rowsPerPage'),
              },
            }}
            pageSize={pageSizeDGs}
            rowsPerPageOptions={constPaginationDataGrid.defaultPageSizesArray}
            onPageSizeChange={(newPageSize) => setPageSizeDGs(newPageSize)}
            pagination
          />
        <Menu 
          open={openMenu}
          onClose={() => setAnchorEl(null)} 
          MenuListProps={{ 'aria-labelledby': 'basic-button' }}
          anchorReference="anchorPosition"
          anchorPosition={
            anchorEl 
              ? anchorEl 
              : undefined
          }
        >

          <MenuItem onClick={ ()=> handleEditSupplier(menuActionSelectedRow) }>
            <ListItemIcon>
              <FontAwesomeIcon icon={faEdit} />
            </ListItemIcon>
            <ListItemText>
            {t('suppliers.buttons.edit')}
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={ ()=> handleModalDeleteSupplier(menuActionSelectedRow) }>
            <ListItemIcon>
              <FontAwesomeIcon icon={faMinusCircle} />
            </ListItemIcon>
            <ListItemText>
              {t('suppliers.buttons.delete')}
            </ListItemText>
          </MenuItem>

        </Menu>
      </Box>
		);
	}

  return (
    <div className="overflow-hidden">
    { loadSupplier || editSupplier ?
      <></> :
      <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <div>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            onClick={() => setLoadSupplier(true)}
            startIcon={<FontAwesomeIcon icon={faPlus} />}>
              {t('suppliers.buttons.add')}
          </Button>
        </div>
      </Stack>
    }

    { loadSupplier || editSupplier
    ? 
    <>

      <LoadSuppliers 
        setLoadSupplier={setLoadSupplier} 
        setEditSupplier={setEditSupplier} 
        supplier={ editSupplier ? supplier : false } 
        reload={loadRows}
        launchToast={launchToast}
        />

    </> 
    : 
    <>
      <DataTable 
        rows={rows} 
        selectedRows={selectedRows}
        onSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection);
        }} 
      />
    </>
    }

      <ModalConfirmOrDecline 
        open={!!deleteSupplier} 
        modalText={`${t('suppliers.modal.deleteConfirmMessage')} ${deleteSupplier.row?.value}?`}
        toggleModal={ setDeleteSupplier }
        handleAction={ handleDeleteSupplier }
        data={deleteSupplier}
      />

      <ToastContainer />

    </div>

  );
}

export { Suppliers };
