import { createTheme, ThemeProvider } from '@mui/material/styles';
import qrTheme from '../qrTheme'

const CustomThemeProvider = ({ modelStyle, children }) => {
    const customTheme = createTheme({
        ...qrTheme,
        palette: {
            ...qrTheme.palette,
            ...modelStyle
        },
    });

    return <ThemeProvider theme={customTheme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
