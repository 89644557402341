import React, { useEffect } from 'react';
import { Grid, Dialog, DialogTitle, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

function ModalConfirmOrDecline({ open, toggleModal, modalText, handleAction, data }) {
  useEffect(() => {
  }, [toggleModal]);

  const { t } = useTranslation();

  return (
    <Dialog onClose={() => toggleModal(false)} open={open}>
      <IconButton
        aria-label="close"
        onClick={() => toggleModal(false)}
        sx={{
          position: 'absolute',
          right: '8px',
          top: '8px',
        }}>
        <CloseIcon />
      </IconButton>
      <DialogTitle className="text-center mt-4 mb-4">
				<Grid container justifyContent="center" className='mb-2'>
					{modalText}
				</Grid>
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            <Button 
							variant="contained"
							onClick={ ()=>handleAction(true,data) }
						>{t('suppliers.buttons.accept')}</Button>
          </Grid>
          <Grid item xs={6}>
						<Button 
							variant="outlined"
							onClick={() => toggleModal(false)}
						>{t('suppliers.buttons.cancel')}</Button>
          </Grid>
        </Grid>
      </DialogTitle>
    </Dialog>
  );
}

export default ModalConfirmOrDecline;
