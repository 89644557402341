import { Typography } from '@mui/material';
import { DownloadButton } from '../../../Components/FileUploader/BaseComponents/SharedComponents';

export const AttributeFile = ({ data, tt }) => {
  const isURL = (value) => {
    try {
      new URL(value);
      return true;
    } catch {
      return false;
    }
  };

  const determineRestoredFile = (value) => {
    if (isURL(value)) {
      return value;
    }
    try {
      return JSON.parse(value);
    } catch {
      console.error("Error parsing JSON:", value);
      return null;
    }
  };

  const restoredFile = determineRestoredFile(data.value);
  const isRestoredFileURL = isURL(restoredFile);

  return (
    <>
      <Typography color="primary.light" variant="h3" mb={1}>
        {tt(`qrTemplate.${data.label}`)}
      </Typography>
      {restoredFile ? (
        isRestoredFileURL ? (
          <a href={restoredFile} target="_blank" rel="noopener noreferrer">
            <DownloadButton restoredFile={restoredFile}/>
          </a>
        ) : (
          <DownloadButton restoredFile={restoredFile}/>
        )
      ) : (
        <Typography color="error">Invalid file data</Typography>
      )}
    </>
  );
};
