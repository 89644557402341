import instance from '../Config/axios'
import { ApiFetch } from '../Utils/ApiFetch';

export const getUserByEmail = async (email) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/users/searchFromEmail/${email}`;

  return instance.get(url, config);
};

export const assignCompanyDelegate = async (user, rol, company) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/users/delegations/company/asign`;

  return instance.post(url, { user, rol, company }, config);
};

export const getDelegationsMade = async () => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/users/delegations/all`;

  return instance.get(url, config);
};

export const getDelegationsReceived = async () => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/users/delegations/received/all`;

  return instance.get(url, config);
};

export const getUsersByBoxId = (boxId, globalContext) => {
  return new Promise((resolve, reject) => {
    if (!boxId) {
      console.error("El boxId es inválido.");
      reject(new Error("El boxId es inválido."));
      return;
    }

    ApiFetch(
      `users/all/box/${boxId}`,
      globalContext,
      true,
      (res) => {
        res.json().then((data) => resolve(data));
      },
      (error) => {
        console.error("Error en la llamada a users/delegations:", error);
        reject(error);
      },
      {
        method: "GET",
      }
    );
  });
};

export const searchBoxUsersFromAlias = async (boxAlias, globalContext) => {
  if (!boxAlias) {
    throw new Error("El alias del box es obligatorio.");
  }

  return new Promise((resolve, reject) => {
    ApiFetch(
      `users/all/box/alias/${boxAlias}`,
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          const data = await res.json();
          resolve(data);
        } else {
          reject(new Error("No se encontraron usuarios para este alias."));
        }
      },
      (error) => {
        console.error("Error buscando el box por alias:", error);
        reject(error);
      },
      { method: "GET" }
    );
  });
};

export const getUsersByCompanyId = async (companyId, globalContext) => {
  if (!companyId) {
    throw new Error("El ID de la compañía es obligatorio.");
  }

  return new Promise((resolve, reject) => {
    ApiFetch(
      `users/company/${companyId}`,
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          const data = await res.json();
          resolve(data);
        } else {
          reject(new Error("No se encontraron usuarios para esta compañía."));
        }
      },
      (error) => {
        console.error("Error obteniendo usuarios de la compañía:", error);
        reject(error);
      },
      { method: "GET" }
    );
  });
};
