import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useThemeContext from '../Hooks/useThemeContext';
import { esES, enUS } from '@mui/x-data-grid';
import { esES as dataPickerEsEs, enUS as dataPickerEnUs} from '@mui/x-date-pickers';
import { esES as coreEsEs, enUS as coreEnUs } from '@mui/material/locale';
import { createTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const LocaleContext = createContext();

export default function LocaleContextProvider ({ children }) {
  const { i18n } = useTranslation();
  let { activeTheme } = useThemeContext();
  
  const [translatedTheme, setTranslatedTheme] = useState(activeTheme);

  useEffect(() => {
    if (i18n.language.includes('es')) {
      setTranslatedTheme(
        createTheme(activeTheme, coreEsEs, esES, dataPickerEsEs)
      )
    } else {
      setTranslatedTheme(
        createTheme(activeTheme, coreEnUs, enUS, dataPickerEnUs)
      )
    }
  }, [i18n.language, activeTheme])
  
  const value={
    translatedTheme
  }

  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
}

LocaleContextProvider.propTypes = {
  children: PropTypes.object,
};
