import { useEffect, useState } from "react";

const API_HOST = process.env.REACT_APP_HOST;
const VERSION = process.env.REACT_APP_VERSION;

export default function useCheckVersion() {
  const [isOutdated, setIsOutdated] = useState(false);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    
    if (currentUrl.searchParams.has("dId")) {
      return;
    }

    const checkVersion = async () => {
      try {
        const response = await fetch(`${API_HOST}version/getVersion`);
        const data = await response.json();

        if (data.version !== VERSION) {
            setIsOutdated(true);
        }
      } catch (error) {
        console.error("Error obteniendo la versión del backend:", error);
      }
    };

    checkVersion();
  }, []);

  return { isOutdated };
}
