import React, { useEffect, useState } from 'react';

import {
  Grid,
  Stepper,
  Step,
  StepButton,
  Divider
} from '@mui/material';
import { ApiFetch } from '../../../Utils/ApiFetch';

import GridMassiveEdit from '../GridMassiveEdit';
import MassiveSummary from '../MassiveSummary';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { useHistory } from 'react-router-dom';

import { TRANSFERS } from '../../../constants/paths';

import ModalMessage from '../../../Components/Feature/ModalMessage';
import ActivosBasicTable from '../../../Layout/components/Activos/ActivosBasicTable';
import BoxTarget from '../BoxTarget';
import { useTranslation } from 'react-i18next';
import { calculateLabelDates } from '../../../Utils/gridDefaultValues';
import { TOAST_TYPE } from '../../../constants';

const ManualContainerTransfer = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const steps = [
    t("stepByStep.massive.spreadsheet.transferStep.selectTransfer"),
    t("stepByStep.massive.spreadsheet.transferStep.selectAssets"),
    t("stepByStep.massive.spreadsheet.transferStep.loadData"),
    t("stepByStep.massive.spreadsheet.transferStep.boxTarget"),
    t("stepByStep.massive.spreadsheet.transferStep.massiveSummary")
  ];
  const [assets, setAssets] = React.useState([]);
  const [pairColumns, setPairColumns] = React.useState([]);
  const [maxStepAvailable, setMaxStepAvailable] = React.useState(0);
  const [activeStep, setActiveStep] = useState(-1);
  const [waitModal, setWaitModal] = React.useState(false);
  const [modeEdit, setModeEdit] = useState('Add');
  const [excelUploadId, setExcelUploadId] = useState(-1);
  const [dataList, setDataList] = useState({});
  const [randomKeyContext, setRandomKeyContext] = useState(0);

  const globalContext = useGlobalContext();
  const { userData } = globalContext;
  const timeZone = userData?.userData?.timezone;

  const [boxTarget, setBoxTarget] = React.useState(0);

  const handleStep = (step) => () => {
    if (step < maxStepAvailable) setActiveStep(step);
  };

  useEffect(() => {
    setRandomKeyContext(new Date().valueOf());
    //setAssets(props.data);
  }, []);

  useEffect(() => {
    if (props.action === 'update') {
      setModeEdit('Edit');
    } else {
      // agregar una fila en la BASE DE DATOS Y Mandar el id
      setModeEdit('Add');
      if (assets.length === 0) addRowForManual();
    }
  }, [props.action]);

  const addRowForManual = () => {
    ApiFetch(
      'parseExcel/addForManualUpload',
      globalContext,
      true,
      async (result) => {
        if (result.status === 200) {
          let resJson = await result.json();

          if (resJson?.ExcelUploadId && !isNaN(resJson?.ExcelUploadId)) {
            setExcelUploadId(resJson.ExcelUploadId);
          }
        }
      },
      (error) => {
        // console.log('debug 01', error);
      },
      {
        method: 'POST',
      }
    );
  };

  useEffect(() => {
    if (excelUploadId !== -1 || modeEdit === 'Edit') setActiveStep(1);
  }, [excelUploadId, modeEdit]);

  const handleSubmit = (list) => {
    setDataList(list);
    setActiveStep(3);

    setMaxStepAvailable(3);
  };

  const handleDataChange = (dataList, dataPairColumns) => {
    // dataList is the setAssets in the MassiveEdit
    setAssets(dataList);
    setPairColumns(dataPairColumns);
  };

  const onBoxSelected = (boxTarget) => {
    let boxTargetObj = {};
    if (boxTarget !== '') {
      boxTargetObj = JSON.parse(boxTarget);
    }
    setBoxTarget(boxTargetObj);
    setActiveStep(4);
  }

  const handleFinalSubmit = () => {
    let dataListTemp = [];

    if (props.action === 'update') {
      dataList.map((data) => {
        let dataTemp = { ...data };
        delete dataTemp.id;
        let dataTempNoValuesFilter = {};
        for (let key of Object.entries(dataTemp)) {
          if (dataTemp[key[0]] != null) {
            dataTempNoValuesFilter[key[0]] = dataTemp[key[0]];
          }
        }
        dataListTemp.push({ ...dataTempNoValuesFilter, pk: data.id });
      });
    }

    if (props.action === 'add') {
      dataListTemp = [...dataList];
    }

    let dataListTempLabels = [];
    for (let row of dataListTemp) {
      if (row._eventDate_) {
        let labelsTemp = {};
        const { dateLabel, dateValue } = calculateLabelDates(
          row._eventDate_,
          timeZone
        );
        labelsTemp._eventDate_ = dateLabel;
        row._eventDate_ = dateValue;
        dataListTempLabels.push(labelsTemp);
      }
    }

    let dataObj = {
      values: dataListTemp,
      labels: dataListTempLabels,
      headerValues: {
        //date:eventDate,
        //comment:eventComment
      },
      action: props.action,
      pk: props.pk,
      objectType: props.objectType, //"asset",
      pkObjectType: props.pkObjectType, //assetContext.objectTypeSubType,
      pkReferer: props.pkReferer,
      holder: props.parentId, //assetContext.parentId,
      boxSource: props.parentId, //assetContext.parentId,
      boxTarget: boxTarget.id, //assetContext.parentId,
      temporalDetailParentId: props.temporalDetailParentId, // -1
      globalModel: props.globalModel, //assetContext.model,
      actionContext: { context: 'massive_manual_form', eventType: 1000 },
    };

    setWaitModal(true);
    ApiFetch(
      'objects/add/assets/transfer/massive',
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          props.onUploadOk(res);
          setWaitModal(false);
          history.push({
            pathname: TRANSFERS,
            state: {
              toast: {
                type: TOAST_TYPE.SUCCESS,
                message: t('breadcrumb.asset.msg.massive_transfer'),
              },
            }
          });
        } else {
          props.onUploadError(res);
        }
      },
      (error) => {
        props.onUploadError(error);
      },
      {
        method: 'POST',
        body: JSON.stringify(dataObj),
      }
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="mt-0">
        <Grid item xs={12} className="mt-2">
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={index < maxStepAvailable}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Divider
          variant="middle"
          className="mt-4"
          style={{ marginBottom: '30px' }}
        />
      </Grid>

      {activeStep === 1 && (
        <Grid item xs={12}>
          <ActivosBasicTable
            event={{eventType: 1000}}
            preSelectedRows={[]}
            sessionContext={randomKeyContext}
            onSelectedRowsChange={(ret) => {
              // console.log('onselectrowsc', ret);
            }}
            exportConfig={{
              mode: true,
              caption: t('stepByStep.button.select'),
            }}
            exportFilterAction={(ret) => {
              // tododromero, ajustar chequeo de consistencia de los datos a enviar
              if (true) {
                setAssets(ret.rows);
                setActiveStep(2);
              }
            }}
          />
        </Grid>
      )}
      {activeStep === 2 && (
        <GridMassiveEdit
          mode={modeEdit}
          data={assets}
          entityMode={props.entityMode}
          eventType={props.eventType}
          pairColumns={pairColumns}
          enableEventDate={true}
          excelUploadId={excelUploadId}
          filterAttributeModel={props.filterAttributeModel}
          onSubmit={handleSubmit}
          onSetAmountErrorsInitial={() => { }}
          globalModel={props.globalModel}
          pkObjectType={props.pkObjectType}
          onDataChange={handleDataChange}
          manualUpload={true}
          eventId={1000}
        />
      )}
      {activeStep === 3 && (
        <>
          <BoxTarget
            assetsList={[...dataList.map(asset => asset.id)]}
            onBoxSelected={(boxTarget) => {
              onBoxSelected(boxTarget);
            }}
            eventType={1000}
          />
        </>
      )}
      {activeStep === 4 && (
        <MassiveSummary
          showControls={true}
          dataList={dataList}
          globalModel={props.globalModel}
          onFinalSubmit={handleFinalSubmit}
          amountErrorsInitial={0}></MassiveSummary>
      )}

      <ModalMessage
        open={waitModal}
        toggleModal={() => { }}
        modalText={t('component.processing')}
      />
    </Grid>
  );
};

export default ManualContainerTransfer;
