import { Grid, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

function CompanyRenewalError() {
    const { t } = useTranslation();

    return(
      <Grid container spacing={2} gap={1}>
          <Grid item xs={12}>
            <Alert severity="error">
              {t('component.alert.companyRenewal')}
            </Alert>
          </Grid>
      </Grid>
    )
}

export default CompanyRenewalError;