const OBJECTS_URL = {
  ADD_MASSIVE: '/objects/add/massive',
  GET_ALL: '/objects/get/all',
  GET: '/objects/get'
}

const BOXES_URL = {
  GET: '/boxes',
  GET_ACTIVITIES: '/boxes/activities'
}

const QR_URL = {
  GET_PRODUCTO_TRUPO: '/qrProduktoTrupo/get',
  GET_BLENDING_TRUPO: '/qrBlendingTrupo/get',
  GET_BREWING_TRUPO: '/qrBrewingTrupo/get',

  GET_IDENTECO_TRUPO: '/qrIdentecoTrupo/get',
  SAVE_IDENTECO_TRUPO_TEMPLATE: '/qrIdentecoTrupo/saveTemplate',
  GET_IDENTECO_TRUPO_TEMPLATE: '/qrIdentecoTrupo/getTemplate',

  GET_IDENTECO: '/qrIdenteco/get',
  SAVE_IDENTECO_TEMPLATE: '/qrIdenteco/saveTemplate',
  GET_IDENTECO_TEMPLATE: '/qrIdenteco/getTemplate'
}

const QR_LOG_URL = {
  POST_IDENTECO: '/qrLogs/qrIdenteco',
  POST_IDENTECO_TRUPO: '/qrLogs/qrIdentecoTrupo',
  POST_PRODUKTO_TRUPO: '/qrLogs/qrProduktoTrupo',
  POST_BLENDING_TRUPO: '/qrLogs/qrBlendingTrupo',
  POST_BREWING_TRUPO: '/qrLogs/qrBrewingTrupo',
}

export { OBJECTS_URL, BOXES_URL, QR_URL, QR_LOG_URL }
