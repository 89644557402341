import { useEffect, useState } from "react";

const useDelegationsFilter = (delegationsMade, delegationsReceived, filters) => {
  const [filteredCompanyDelegations, setFilteredCompanyDelegations] = useState([]);
  const [filteredBoxDelegations, setFilteredBoxDelegations] = useState([]);
  const [filterSuplychain, setFilterSuplychain] = useState([]);
  const [filterActive, setFilterActive] = useState([]);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const [filterEstablishments, setFilterEstablishments] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);

  useEffect(() => {
    let tempSuplychains = [];
    let tempActive = [];
    let tempCompanies = [];
    let tempEstablishments = [];
    let tempUsers = [];

    processDelegations(delegationsMade, "made", tempSuplychains, tempActive, tempCompanies, tempEstablishments, tempUsers);
    processDelegations(delegationsReceived, "receive", tempSuplychains, tempActive, tempCompanies, tempEstablishments, tempUsers);

    setFilterSuplychain(tempSuplychains);
    setFilterActive(tempActive);
    setFilterCompanies(tempCompanies);
    setFilterEstablishments(tempEstablishments);
    setFilterUsers(tempUsers);
  }, [delegationsMade, delegationsReceived]);

  useEffect(() => {
    setFilteredCompanyDelegations(
      delegationsMade.filter((delegation) =>
        delegation.type === "COMPANY" &&
        ((filters.company && filters.company === delegation.id) ||
          (!filters.company && filters.suplychain &&
            delegation.relation_data.some(data => data.suplychainId === filters.suplychain)) ||
          (!filters.suplychain && !filters.company))
        && (filters.users === delegation.user_owner_id || !filters.users)
      )
    );

    setFilteredBoxDelegations(
      delegationsMade.filter((delegation) =>
        delegation.type === "BOX" &&
        (
          (filters.establishment && filters.establishment === delegation.id) ||
          (!filters.establishment && filters.company && filters.company === delegation.relation_data[0]?.companyId) ||
          (!filters.establishment && !filters.company && filters.suplychain &&
            delegation.relation_data[0]?.suplychainId === filters.suplychain) ||
          (!filters.establishment && !filters.suplychain && !filters.company)
        ) &&
        (filters.users === delegation.user_owner_id || !filters.users)
      )
    );

  }, [filters, delegationsMade, delegationsReceived]);

  const processDelegations = (delegations, type, tempSuplychains, tempActive, tempCompanies, tempEstablishments, tempUsers) => {
    delegations.forEach(delegation => {
      delegation.relation_data.forEach(relation => {
        if (!tempSuplychains.some(item => item.id === relation.suplychainId)) {
          tempSuplychains.push({ id: relation.suplychainId, name: relation.suplychainName });
        }

        relation.assetType.forEach(asset => {
          if (!tempActive.some(item => item.id === asset.id)) {
            tempActive.push({ fk: relation.suplychainId, id: asset.id, name: asset.name });
          }
        });

        if (delegation.type === 'COMPANY') {
          const existingCompany = tempCompanies.find(item => item.id === delegation.id);
          if (!existingCompany) {
            tempCompanies.push({ fk: [relation.suplychainId], id: type === 'made' ? delegation.id : delegation.id_entity, name: delegation.name });
          } else if (!existingCompany.fk.includes(relation.suplychainId)) {
            existingCompany.fk.push(relation.suplychainId);
          }
        }

        if (delegation.type === 'BOX') {
          if (!tempEstablishments.some(item => item.id === delegation.id)) {
            tempEstablishments.push({
              fkcompanyId: relation.companyId,
              fkType: relation.assetType[0].id,
              id: type === 'made' ? delegation.id : delegation.id_entity,
              name: delegation.name
            });
          }
        }
      });

      if (!tempUsers.some(user => user.id === (type === 'made' ? delegation.user_owner_id : delegation.delegated_by_user_id))) {
        tempUsers.push({
          id: type === 'made' ? delegation.user_owner_id : delegation.delegated_by_user_id,
          name: type === 'made' ? delegation.user_owner_name : delegation.delegated_by_user_name
        });
      }
    });
  };

  return {
    filteredCompanyDelegations,
    filteredBoxDelegations,
    filterSuplychain,
    filterActive,
    filterCompanies,
    filterEstablishments,
    filterUsers,
  };
};

export default useDelegationsFilter;
