import { Typography } from '@mui/material';

export const AttributePlain = ({ data, tt }) => {

 return (
    <>
        <Typography color='primary.main' variant='h3'>
          {tt(`qrTemplate.${data.label}`)}
        </Typography>
        <Typography color='text.primary' variant='subtitle1'>
            {
                data.value
            }
        </Typography>
    </>
 );
};
