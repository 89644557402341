import React, { useEffect, useState } from 'react';
import { ApiFetch } from '../../Utils/ApiFetch';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { Grid, Button, Typography, Card, Stepper, Step, Divider, StepButton, InputAdornment, TextField, Alert, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import SidebarTopComponent from '../../Layout/components/Sidebar/SidebarTopComponent';
import SelectImageField from '../../Components/Generic/Fields/SelectImageField';
import { useTranslation } from 'react-i18next';
import { createBoxInvitation } from '../../services/invitation';
import { searchBoxUsersFromAlias } from '../../services/users';
import { getTopBarItems } from '../../services/sideBar';
import DelegationErrorAlerts from './Components/DelegationErrorAlerts';
import DelegationMassiveUserList from './Components/DelegationMassiveUserList';

const delegationTypes = [
  { labelKey: "component.roles.userDelegation", value: "user", isMassive: false },
  { labelKey: "component.roles.userGroupDelegation", value: "userGroup", isMassive: true },
];

function UserDelegationsStepper(props) {
  const { t } = useTranslation();
  const globalContext = useGlobalContext();
  const [step, handleStep] = useState(0);
  const [selectedBox, setSelectedBox] = useState(null);
  const [roles, setRoles] = useState([]);
  const [selectedRol, setSelectedRol] = useState(null);
  const [userFound, setUserFound] = useState(null);
  const [email, setEmail] = useState("");
  const [showNotFound, setShowNotFound] = useState(false);
  const [assignError, setAssignError] = useState(false);
  const [assignWarning, setAssignWarning] = useState(false);
  const [inviteError, setInviteError] = useState(false);
  const [isMassiveDelegation, setIsMassiveDelegation] = useState(false);
  const [userList, setUserList] = useState([]);  
  const [failedUsers, setFailedUsers] = useState([]);
  const [massiveAssignError, setMassiveAssignError] = useState(false);
  const [boxAlias, setBoxAlias] = useState("");
  const [message, setMessage] = useState("");
  const [targetData, setTargetData] = useState({
    chain: { id: -1, name: '' },
    active: { id: -1, name: '' },
    company: { id: -1, name: '' },
    establishment: { id: -1, name: '', subtype: -1, nav: {} },
  })

  const searchBoxEmail = () => {
    setShowNotFound(false);
    setUserFound(null);

    ApiFetch(
      'users/searchFromEmail/' + email,
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          let data = await res.json();
          if (data.length > 0) {
            setUserFound(data[0]);
            setShowNotFound(false);
          } else {
            setUserFound(null);
            setShowNotFound(true);
          }
        }
      },
      (error) => { },
      {
        method: 'GET',
      }
    );
  };

  const searchUsersFromAlias = async () => {
    try {
      setShowNotFound(false);
      setMessage("");
      const data = await searchBoxUsersFromAlias(boxAlias, globalContext);
      if (data && data.length > 0) {
        setUserList(data);
        setMessage(t("stepByStep.massive.target.establishmentFound"));
      } else {
        setMessage(t("stepByStep.massive.target.noUsersFoundForAlias"));
        setUserList([]);
        setShowNotFound(true);
      }
    } catch (error) {
      console.error("Error en la búsqueda del box por alias:", error);
      setMessage(t("stepByStep.massive.target.noUsersFoundForAlias"));
      setUserList([]);
      setShowNotFound(true);
    }
  };

  const getAllRoles = (box) => {
    const boxId = typeof box === "object" ? box.id : box;

    if (!boxId) {
      console.error("El ID del box es obligatorio para cargar los roles.");
      return;
    }
    ApiFetch(
      'boxes/roles/' + boxId,
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          let data = await res.json();
          if (data.length > 0) {
            let dataTemp = [];
            for (let item of data) {
              let itemTemp = {
                key: item.id,
                label: item.name
              };
              dataTemp.push(itemTemp);
            }
            setRoles(dataTemp);
          }
        }
      },
      (error) => {
        console.log('error boxes/roles', error);
      },
      {
        method: 'GET',
      }
    );
  };

  const selectBox = async (box) => {
    getAllRoles(box);
    setSelectedBox(box);
  };

  const inviteUser = async () => {
    try {
      const res = await createBoxInvitation({
        email,
        box_id: selectedBox.id,
        permission_template_id: selectedRol,
      });
      props.showModal(res.data.message);
      if (props.handleAsign) {
        props.handleAsign();
      }
    } catch (error) {
      setInviteError(true);
      console.error('Error invitando al usuario:', error);
    }
  };

  const handleChangeDelegation = (event) => {
    const { value } = event.target;
    const selectedOption = delegationTypes.find((type) => type.value === value);

    if (selectedOption) {
      setIsMassiveDelegation(selectedOption.isMassive);
    } else {
      setIsMassiveDelegation(false);
    }
  };

  const asignRol = async (userEmail = email, id_template = selectedRol, box = selectedBox) => {
    let data = {
      user: userEmail,
      rol: id_template,
      box: box
    };
  
    return ApiFetch(
      'users/delegations/asign',
      globalContext,
      true,
      async (res) => {
        if (res.status !== 200) {
          throw new Error(`Error asignando usuario ${email}`);
        } else {
          props.showModal("La delegación fue asignada exitosamente.");
          props.onCancel();
        }
      },
      (error) => {
        if (error.status === 403) {
          setAssignWarning(true);
        } else {
          setAssignError(true);
        }
        console.log('error users/delegations/asign', error);
      },
      {
        method: 'POST',
        body: JSON.stringify(data),
      }
    );
  };  
  
  const assignRolesToUsers = async () => {
    try {
      const validUsers = userList.filter(user => user.id_template && user.rol);
      if (validUsers.length === 0) {
        console.warn("No hay usuarios válidos para asignar roles.");
        return;
      }
  
      const failed = [];
  
      const promises = validUsers.map(async (user) => {
        try {
          await asignRol(user.email, user.id_template, selectedBox);
        } catch (error) {
          console.error(`Error asignando rol a ${user.email}:`, error);
          failed.push(user);
        }
      });
  
      await Promise.all(promises);
  
      if (failed.length > 0) {
        setFailedUsers(failed);
        setMassiveAssignError(true);
      } else {
        props.showModal("Los roles fueron asignados exitosamente.");
        props.onCancel();
        setUserList([]);
      }
    } catch (error) {
      console.error("Error en la asignación masiva:", error);
      setMassiveAssignError(true);
    }
  };  

  useEffect(() => {
    if (props.boxId) {
      const fetchEstablishments = async () => {
        try {
          const res = await getTopBarItems();
  
          const establishments = res?.data?.establishments || [];
          const companies = res?.data?.companies || [];
          const actives = res?.data?.actives || [];
          const supplyChains = res?.data?.suplyChain || [];
  
          const establishment = establishments.find(
            (item) => item.id === props.boxId
          );
  
          if (!establishment) {
            console.warn("Establishment no encontrado para el boxId:", props.boxId);
            return;
          }

          const company = companies.find(
            (item) => item.id === establishment.fk
          );
  
          if (!company) {
            console.warn("Company no encontrado para el fk:", establishment.fk);
            return;
          }
  
          const active = actives.find((item) => item.id === company.fk);
  
          if (!active) {
            console.warn("Active no encontrado para el fk:", company.fk);
            return;
          }
  
          const supplyChain = supplyChains.find(
            (item) => item.id === active.fk
          );
  
          if (!supplyChain) {
            console.warn("SupplyChain no encontrado para el fk:", active.fk);
            return;
          }

          const result = {
            chain: { id: supplyChain.id, name: supplyChain.value },
            active: { id: active.id, name: active.value },
            company: { id: company.id, name: company.value },
            establishment: {
              id: establishment.id,
              name: establishment.value,
              subtype: establishment.subtype,
              nav: {},
            },
          };
  
          selectBox({
            id: establishment.id,
            name: establishment.value,
            subtype: establishment.subtype,
            nav: {},
          })
          setTargetData(result);
  
        } catch (error) {
          console.error("Error obteniendo datos:", error);
        }
      };
  
      fetchEstablishments();
    }
  }, [props.boxId]);  

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="mt-0">
        <Grid item xs={12} className="mt-2">
          <Stepper nonLinear activeStep={step}>
            <Step key={0}>
              <StepButton color="inherit">
                {t("component.roles.step.selectDelegation")}
              </StepButton>
            </Step>
            <Step key={1}>
              <StepButton color="inherit">
                {!isMassiveDelegation 
                  ? t("component.roles.step.selectUser") 
                  : t("component.roles.step.selectTargetBox")}
              </StepButton>
            </Step>
            <Step key={2}>
              <StepButton color="inherit">
                {!isMassiveDelegation 
                  ? t("component.roles.step.selectBox") 
                  : t("component.roles.step.selectSourceBox")}
              </StepButton>
            </Step>
            <Step key={3}>
              <StepButton color="inherit">
                {t("component.roles.step.selectRole")}
              </StepButton>
            </Step>
          </Stepper>
        </Grid>
        <Divider variant="middle" className="mt-4" style={{ marginBottom: '5px' }} />
      </Grid>

      {/* STEP 0 - Selección de delegación */}
      {step === 0 && (
        <Grid container display="flex" className="p-2" alignItems="center" justifyContent="center">
          <FormControl fullWidth>
            <InputLabel size={"small"}>{t("component.roles.step.typeOfDelegation")}</InputLabel>
            <Select
              size={"small"}
              value={isMassiveDelegation ? "userGroup" : "user"}
              onChange={handleChangeDelegation}
              id="select"
              placeholder={t("component.roles.step.typeOfDelegation")}>
              {delegationTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {t(type.labelKey)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ alignSelf: 'flex-end', position: 'absolute', bottom: 35 }}>
            <Button onClick={() => props.onCancel()} sx={{ mr: 1, mt: 3 }} variant="outlined">
              {t('stepByStep.button.cancel')}
            </Button>
            <Button onClick={() => handleStep(1)} sx={{ mr: 1, mt: 3 }} variant="contained">
              {t('stepByStep.button.next')}
            </Button>
          </div>
        </Grid>
      )}

      {/* STEP 1 - Selección de usuario o Box Destino */}
      {step === 1 && (
        <Grid container display="flex" className="p-2" alignItems="center" justifyContent="center">
          {!isMassiveDelegation ? (
            <>
            <Grid item xs={12} className="mt-3" alignItems="center" justifyContent="center">
              <Typography variant="subtitle1">{t("component.roles.user")}</Typography>
              <div className="d-flex align-center mt-2">
                <TextField
                  name="user"
                  size="medium"
                  style={{ width: "100%" }}
                  value={email}
                  onChange={(val) => setEmail(val.target.value)}
                  placeholder={t("component.roles.user")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faSearch} color="#61B15A" />
                      </InputAdornment>
                    ),
                  }}
                />&nbsp;
                <Button onClick={searchBoxEmail} variant="contained">
                  {t('stepByStep.button.search')}
                </Button>
              </div>
            </Grid>
            {(showNotFound) &&
              <div className="d-flex align-center mt-2" style={{ color: "orange" }}>
                {t("component.roles.noEmailFound")}
              </div>
            }
            {userFound && (
              <Grid item xs={12} className="mt-3">
                <Grid component={Card} container>
                  <Grid item xs={9} className="p-4">
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography>{t("component.roles.firstName")}</Typography>
                        <Typography>{t("component.roles.lastName")}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography><b>{userFound.firstname}</b></Typography>
                        <Typography><b>{userFound.lastname}</b></Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            </>
          ) : (
            <SidebarTopComponent
              data={targetData}
              handleBoxSelected={(data) => {
                selectBox(data.establishment);
              }}
            />
          )}
          <div style={{ alignSelf: 'flex-end', position: 'absolute', bottom: 35 }}>
            <Button onClick={() => props.onCancel()} sx={{ mr: 1, mt: 3 }} variant="outlined">
              {t('stepByStep.button.cancel')}
            </Button>
            <Button onClick={() => handleStep(0)} sx={{ mr: 1, mt: 3 }} variant="outlined">
              {t('stepByStep.button.back')}
            </Button>
            <Button disabled={
              (isMassiveDelegation && !selectedBox) ||
              (!isMassiveDelegation && !userFound && !showNotFound)
            } 
            onClick={() => {
              handleStep(2);
            }}        
            sx={{ mr: 1, mt: 3 }} 
            variant="contained">
            {t('stepByStep.button.next')}
            </Button>
          </div>
        </Grid>
      )}

      {/* STEP 2 - Selección de Box Destino (delegacion individual) o Box Oriden mediante alias (delegacion establcimiento) */}
      {step === 2 && (
        <Grid container display="flex" alignItems="center" justifyContent="center" className="p-2">
          {!isMassiveDelegation ? (
            <SidebarTopComponent
              data={null}
              handleBoxSelected={(data) => {
                selectBox(data.establishment);
              }}
            />
          ) : (
            <Grid item xs={12} className="mt-1">
              <Typography variant="caption" component="p" className="ml-2">
                {t("stepByStep.massive.target.title")}
              </Typography>
              <div className="d-flex align-center ">
                <TextField
                  id="filled-search"
                  type="search"
                  className="mr-2"
                  fullWidth
                  value={boxAlias}
                  onChange={(val) => setBoxAlias(val.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faSearch} color="#61B15A" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button disabled={!boxAlias} onClick={searchUsersFromAlias} variant="contained">
                  {t('stepByStep.button.search')}
                </Button>
              </div>
              {message && (
                <div className="d-flex align-center mt-2" style={{ color: showNotFound ? "red" : "green" }}>
                  {message}
                </div>
              )}
            </Grid>
          )}
          <div style={{ alignSelf: 'flex-end', position: 'absolute', bottom: 35 }}>
            <Button onClick={() => props.onCancel()} sx={{ mr: 1, mt: 3 }} variant="outlined">
              {t('stepByStep.button.cancel')}
            </Button>
            <Button onClick={() => handleStep(1)} sx={{ mr: 1, mt: 3 }} variant="outlined">
              {t('stepByStep.button.back')}
            </Button>
            <Button disabled={
              (isMassiveDelegation && userList.length === 0)
            } onClick={() => handleStep(3)} sx={{ mr: 1, mt: 3 }} variant="contained">
              {t('stepByStep.button.next')}
            </Button>
          </div>
        </Grid>
      )}

      {/* STEP 3 - Selección de roles */}
      {step === 3 && (
        <Grid container display="flex" alignItems="center" justifyContent="center" className="p-2">
          <Grid item xs={12}>
            {!isMassiveDelegation ? (
              <SelectImageField
                fieldValues={roles}
                name="roles"
                onChange={(field, value) => setSelectedRol(value)}
                value={selectedRol}
              />
            ) : (
              <DelegationMassiveUserList userList={userList} roles={roles} setUserList={setUserList} t={t}/>
            )}
            
            <DelegationErrorAlerts
              assignError={assignError} 
              assignWarning={assignWarning} 
              inviteError={inviteError} 
              massiveAssignError={massiveAssignError} 
              failedUsers={failedUsers} 
              t={t} 
            />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 16 }}>
              <Button onClick={() => props.onCancel()} sx={{ mr: 1, mt: 3 }} variant="outlined">
                {t('stepByStep.button.cancel')}
              </Button>
              <Button onClick={() => handleStep(2)} sx={{ mr: 1, mt: 3 }} variant="outlined">
                {t('stepByStep.button.back')}
              </Button>
              <Button 
                disabled={isMassiveDelegation ? !userList.some(user => user.id_template) : !selectedRol}
                onClick={isMassiveDelegation ? assignRolesToUsers : (showNotFound ? inviteUser : () => asignRol())}
                sx={{ mr: 1, mt: 3 }} 
                variant="contained"
              >
                {isMassiveDelegation 
                  ? t('stepByStep.button.assign') 
                  : (showNotFound ? t('stepByStep.button.invite') : t('stepByStep.button.assign'))}
              </Button>
            </div>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default UserDelegationsStepper;
