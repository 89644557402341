import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEllipsisV, faMinusCircle, faPlus } from '@fortawesome/pro-light-svg-icons';
import { dateSanitizer } from '../../Helpers/dateFormater';
import iconNotStampedNFT from '../../Images/nft_notstamped.png';
import iconStampedNFT from '../../Images/nft_stamped.png';
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify';
import ModalErrorInfo from '../../Components/Feature/ModalErrorInfo';
import { Button, Card, Checkbox, Dialog, Divider, FormControl, Grid, InputLabel, ListItemIcon, ListItemText, Menu, MenuItem, Select } from '@mui/material';
import { DataGridOracles } from './DataGridOracles';
import { useTranslation } from 'react-i18next';
import { ApiFetch } from '../../Utils/ApiFetch';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { LinkAssetOracleModal } from './LinkAssetOracleModal';
import { useHistory } from 'react-router-dom';
import { NEW_ASSETS_ORACLES } from '../../constants/paths';

const LinkAssetsOracles = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const globalContext = useGlobalContext();
  const { selectedAsset, selectedBox } = useGlobalContext();

  const [rowsAssets, setRowsAssets] = useState([]);
  const [columnsAssets, setColumnsAssets] = useState([]);
  const [oraclesData, setOraclesData] = useState([]);
  const [oracleFilter, setOracleFilter] = useState({});
  const [vinculationFilter, setVinculationFilter] = useState({});

  const [openActionModal, setOpenActionModal] = useState(false);
  const [labelActionModal, setLabelActionModal] = useState('');
  const [menuActionRow, setMenuActionRow] = useState(null);
  const [oracleInModal, setOracleInModal] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const [loadingAssetsTable, setLoadingAssetsTable] = useState(false);
 
  const [modalError, setModalError] = useState(false);
  const [messageError, setMessageError] = useState({
    message: null,
    data: null
  });

  const rowMenuActionshandleClick = (event, row) => {
    setMenuActionRow(row)
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    searchAssetsWithFilters()
    let models = globalContext.getSessionDataByKey('models');

    let attributes = []
    if (selectedAsset.id !== -1) {
      if (models != null) {
        attributes = models[selectedAsset.id].attributes.filter(att => att.hideInList === false)
        setColumnsAssets(attributes)
      }
    }

    if (attributes.length === 0) return;
    let ret = [];

    ret.push({ field: 'actions', headerName: '', minWidth: "30", width: "30", sortable: false, disableColumnMenu: true,
      renderCell: (params) => {
      const isDisabled = 
        params.row.asset_status_change !== 'Activo' && 
        params.row.asset_status_change !== 'Validado' &&
        params.row.asset_status_change !== 'Observado'

      return (
        <>
          {isDisabled ? 
            <></> :
            <span
              id="basic-button"
              aria-controls="basic-menu"
              style={{ minWidth: "20px", textAlign: "center", cursor: "pointer" }}
              aria-label="More"
              onClick={(event) => rowMenuActionshandleClick(event, params)}>
              <FontAwesomeIcon style={{ fontSize: '25px' }} icon={faEllipsisV} />
            </span>
          }
        </>
      );
    }});

    ret.push({field: 'id', headerName: 'Id', hide: true});

    for (let field of attributes) {
      let flagAdd = false;
      if (field.permissions.list['box'].includes(parseInt(selectedBox.subtype)) === true) {
        flagAdd = true;
      }

      if (flagAdd) {
        if (field.type === 'file') {
          ret.push({
            field: field.name,
            headerName: field.label,
            width: 190,
            renderCell: (params) => {
              let image = <></>
              if (params.row[field.name] != null && params.row[field.name] !== "") {
                let file = JSON.parse(params.row[field.name]);
                if (file?.originalName) {
                  let originalNameSplit = file.originalName.split(".");
                  if (['JPG', 'PNG', 'JPEG', 'TIFF', 'BMP'].includes(originalNameSplit[originalNameSplit.length - 1].toUpperCase())) {
                    image = <img style={{ maxWidth: "30px", borderStyle: "dotted", borderWidth: "1px", verticalAlign: "middle", marginRight: "5px" }} src={file.publicFileURI} alt=""></img>
                  }
                }
                const onDownload = () => {
                  const link = document.createElement("a");
                  link.href = file.publicFileURI;
                  link.click();
                };
                if (params.row[field.name] != null && params.row[field.name] !== "") {
                  return (
                    <div>
                      {image}
                      <Button
                        sx={{ ml: 2 }}
                        variant="outlined"
                        size="small"
                        startIcon={<FontAwesomeIcon icon={faDownload} />}
                        onClick={() => { onDownload() }}
                        style={{ padding: "5px", margin: "5px", fontSize: "12px" }}
                      >
                        {t("largeGrid.button.download")}
                      </Button>

                    </div>
                  )
                }
              }
            }
          })
        } else if (field.type === 'date') {
          ret.push({
            field: field.name,
            headerName: field.label,
            width: 150,
            fieldType: field.type,
            valueFormatter: (params) => dateSanitizer(params?.value)
          })
        } else if (field.type === 'nft_link') {
          ret.push({
            field: 'Id', 
            headerName: 'Id',
            width: 100,
            valueGetter: (params) => params.row['id'],
            renderCell: (params) => {
              if (params.row[field.name] === "" || params.row[field.name] == null) {
                return <>
                  <img alt=""
                    style={{ width: "30px", marginLeft: "2px", marginRight: "5px", cursor: "pointer" }} src={iconNotStampedNFT}
                  />
                  {params.row['id']}
                </>;
              } else {
                return <>
                  <a target="_BLANK" style={{ marginLeft: "2px", marginRight: "5px" }} href={params.row[field.name]} rel="noreferrer">
                    <img alt="" style={{ width: "30px" }} src={iconStampedNFT} /></a>{params.row['id']}
                </>;
              }
            }
          })
        } else if (field.type === 'checkbox' ) {
          ret.push({
            field: field.name, headerName: field.label, sortable: true, width: '120', renderCell: (
              (params) => {
                return (
                  <Checkbox
                    sx={{width: '100%'}}
                    id={params.id}
                    disabled={true}
                    checked={params.row[field.name] === "true" ? true : false}
                  />
                )
              }
            )
          })
        } else if (field.type === 'select' && field.buttonInGrid) {
          ret.push({
            field: field.name,
            headerName: field.label,
            width: 140,
            justifyContent: 'center',
            renderCell: (params) => {
              let objAssetColor = null

              field.colorsButton.forEach(asset => {
                if (objAssetColor === null && asset.label === params.row[field.name]) {
                  objAssetColor = asset.color
                }
              })

              if(!objAssetColor) objAssetColor = '#76CC81'

              return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                <Button
                  style={{
                    padding: "0px",
                    margin: "0px",
                    fontSize: "11px",
                    paddingTop: '0.2rem',
                    background: objAssetColor,
                    color: 'black',
                    height: '25px',
                    width: '80%'
                  }}
                  size='medium'
                  variant="contained"
                >
                  {params.row[field.name]}
                </Button>
              </div>
            }
          })
        } else if (field.type === 'none') {
          // field.type none no muestra la columna en la tabla de assets.
        } else {
          ret.push({
            field: field.name,
            headerName: field.label,
            width: 150
          })
        }
      }
    }
    setColumnsAssets(ret);
  }, [globalContext, selectedAsset.id, selectedBox.subtype]);

  useEffect(() => {
    if(selectedAsset.id) {
      ApiFetch(
        `oracles/${selectedAsset.id}`,
        globalContext,
        true,
        async (res) => {
          if (res.status === 200) {
            setOraclesData(await res.json());
          }
        },
        (error) => {
          console.log('error oracles', error);
        },
        {
          method: 'GET',
        }
      );
    }
  },[selectedAsset, globalContext])

  const searchAssetsWithFilters = () => {
    setLoadingAssetsTable(true)
    ApiFetch(
      `oracles/assets/${selectedAsset.id}/${selectedBox.id}/${oracleFilter.value ? oracleFilter.value : 0}/${vinculationFilter.value ? vinculationFilter.value : 0}`,
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          setRowsAssets(await res.json());
        }
        setLoadingAssetsTable(false)
      },
      (error) => {
        console.log('error oracles', error);
        setRowsAssets([]);
        setLoadingAssetsTable(false)
      },
      {
        method: 'GET',
      }
    );
  }
  
  const newAssetAction = () => {
    history.push({
      pathname: NEW_ASSETS_ORACLES,
      state: {},
    });
  }

  return (<>
    <ModalErrorInfo
      open={modalError}
      toggleModal={() => {
        setModalError(false);
        setMessageError({message:null, data:null})
      }}
      modalText={
        messageError.message === 'noSoportedOracle' ? t('component.noSoportedOracle') :
        messageError.message === 'dataRepeated' ? messageError.data.map(data => `${t('component.dataRepeated', {code: data.oracleCode, asset: data.asset})}`).join('<br />')
        : t('component.comErrorList')
      }
    />
    <ToastContainer/>
    <Grid container alignItems="center">
        <Button
          sx={{ ml: 2 }}
          variant="contained"
          onClick={() => {
            newAssetAction();
          }}
          startIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          {t('assetsoracles.button.new_asset_oracle')}
        </Button>
      <Grid component={Card} container sx={{ p: 2, mb: 2, mt: 2, alignItems: 'center', boxShadow: "1" }}>
        <Grid item component={FormControl} sx={{ pr: 2 }} xs={3} key={'filterOracle'}>
          <FormControl fullWidth>
            <InputLabel 
              size="small" 
              id={'demo-controlled-open-select-label-oracle'}
            >
              {t(`assetsoracles.filters.oracleFilter`)}
            </InputLabel>
            <Select 
              name={'oracleFilter'}
              label={t(`assetsoracles.filters.oracleFilter`)}
              size="small"
              labelId={'demo-controlled-open-select-label-oracle'}
              value={oracleFilter.value}
              id="select"
              placeholder={'Seleccione el oraculo'}
              onChange={(e) => setOracleFilter(e.target)}
            >
              <MenuItem value=""><em>{t(`assetsoracles.filters.deselect`)}</em></MenuItem>
              {oraclesData.map(oracle => (<MenuItem value={oracle.key}><em>{oracle.label}</em></MenuItem>))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item component={FormControl} sx={{ pr: 2 }} xs={3} key={'filterOracle'}>
          <FormControl fullWidth>
            <InputLabel 
              size="small" 
              id={'demo-controlled-open-select-label-vinculation'}
            >
              {t(`assetsoracles.filters.linkFilter`)}
            </InputLabel>
            <Select 
              name={'vinculationFilter'}
              label={t(`assetsoracles.filters.linkFilter`)}
              size="small"
              labelId={'demo-controlled-open-select-label-vinculation'}
              value={vinculationFilter.value}
              id="select"
              placeholder={'Seleccione el oraculo'}
              onChange={(e) => setVinculationFilter(e.target)}
            >
              <MenuItem value=""><em>{t(`assetsoracles.filters.deselect`)}</em></MenuItem>
              <MenuItem value="false"><em>{t(`assetsoracles.filters.nolink`)}</em></MenuItem>
              <MenuItem value="true"><em>{t(`assetsoracles.filters.link`)}</em></MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item component={FormControl} sx={{ pr: 2, mt: 2, justifyContent: 'flex-end' }} xs={6}>
          <Button variant="outlined" onClick={() => { searchAssetsWithFilters() }}>
            {t("filter.button.apply")}
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} className="mt-2">
        <DataGridOracles
          rows={rowsAssets}
          columns={columnsAssets.filter(column => !column.hide)} 
          type='assets'
          checkboxSelect={false}
          loading={loadingAssetsTable}
        />
        <Menu
          sx={{
            boxShadow: 1
          }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={()=>setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              setOpenActionModal(true)
              setLabelActionModal('link')
              setOracleInModal(menuActionRow?.row?.stock_provider_existence)
            }}
            disabled={!menuActionRow?.row?.stock_provider_existence}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faMinusCircle} />
            </ListItemIcon>
            <ListItemText>{t(`assetsoracles.actions.link`)}</ListItemText>
          </MenuItem>
          <Divider sx={{ mt: '0!important', mb: '0!important' }} />
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              setOpenActionModal(true)
              setLabelActionModal('unlink')
            }}
            disabled={!menuActionRow?.row?.id_stock_provider}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faMinusCircle} />
            </ListItemIcon>
            <ListItemText>{t(`assetsoracles.actions.unlink`)}</ListItemText>
          </MenuItem>
          <Divider sx={{ mt: '0!important', mb: '0!important' }} />
        </Menu>
      </Grid>
      <Dialog 
        open={openActionModal}
        onClose={()=>setOpenActionModal(false)}
      >
        <LinkAssetOracleModal
          action={labelActionModal}
          setOpenActionModal={setOpenActionModal}
          assetDataRow={menuActionRow?.row}
          globalContext={globalContext}
          selectedAsset={selectedAsset}
          selectedBox={selectedBox}
          setMessageError={setMessageError}
          setModalError={setModalError}
          searchAssetsWithFilters={searchAssetsWithFilters}
          oracleInModal={oracleInModal}
        />
      </Dialog>
    </Grid>
  </>)
}

export default LinkAssetsOracles