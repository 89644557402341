import { useTheme } from '@mui/styles';
import React from 'react';

export const TrazabilidadBackground = () => {
  const theme = useTheme();
  const backgroundDefault = theme?.palette?.background?.default;

  return (
    <>
      {!backgroundDefault ?
        <>
          <img
            src='/images/vectores/vectores-2.svg'
            className='vector-left'
            alt='vectores'
          />
          <img
            src='/images/vectores/vectores-1.svg'
            className='vector-right'
            alt='vectores'
          />

          <img
            src='/images/vectores/vectores-3.svg'
            className='vector-midel'
            alt='vectores'
          />
          <img
            src='/images/vectores/vectores-4.svg'
            className='vector-midel-right'
            alt='vectores'
          />  
        </> : <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: backgroundDefault,
          zIndex: -1
        }}/>
      }
    </>
  );
};
