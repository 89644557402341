import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom';
import originoLogoFull from "../Images/origino_logo_full_landscape.jpg";
import { fetchBoxDelegatedUsers, fetchCompanyDelegatedUsers } from '../services/delegations';
import OrgChart from '../pages/User/Components/DelegationOrgChart';

const generateAndCaptureOrgChart = async (delegation, users) => {
    return new Promise((resolve) => {
      const orgChartRef = document.createElement('div');
      document.body.appendChild(orgChartRef);
  
      ReactDOM.render(<OrgChart delegation={delegation} users={users} />, orgChartRef, async () => {
        await new Promise(resolve => requestAnimationFrame(resolve));
  
        const canvas = await html2canvas(orgChartRef);
        const imgData = canvas.toDataURL('image/jpeg', 0.7);
  
        ReactDOM.unmountComponentAtNode(orgChartRef);
        document.body.removeChild(orgChartRef);
  
        resolve({ imgData });
      });
    });
  };

export const exportToPdfDelegations = async (filteredCompanyDelegations, filteredBoxDelegations, globalContext) => {
  if (filteredCompanyDelegations.length === 0 && filteredBoxDelegations.length === 0) {
    console.warn("No hay delegaciones para exportar.");
    return;
  }

  const pdf = new jsPDF({ orientation: 'landscape', unit: 'mm', format: 'a4' });
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  const img = new Image();
  img.src = originoLogoFull;
  await new Promise(resolve => img.onload = resolve);

  pdf.addImage(img, 'PNG', 0, 0, pageWidth, pageHeight);

  const processData = async (delegations, fetchUsers) => {
    for (const delegation of delegations) {
      try {
        const users = await fetchUsers(delegation.id, globalContext);
        const { imgData } = await generateAndCaptureOrgChart(delegation, users);

        pdf.addPage();
        pdf.addImage(imgData, 'PNG', (pageWidth - 200) / 2, (pageHeight - 150) / 2, 200, 0);
      } catch (error) {
        console.error(`Error procesando`, error);
      }
    }
  };

  await processData(filteredCompanyDelegations, fetchCompanyDelegatedUsers);
  await processData(filteredBoxDelegations, fetchBoxDelegatedUsers);

  pdf.save(`Delegaciones_Completas.pdf`);
};
