import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

function SelectField(props) {
  const { name, value, disabled, label, onChange, fieldValues, size, fieldFk, valueFk, t } = props;
  const [filterValues, setFilterValues] = useState()

  useEffect(() => {
    let valuesFiltered = fieldValues

    if(fieldFk){
      valuesFiltered = fieldValues.map(field => {
        if(field.fk === valueFk)
          return field
        return null
      }).filter(field => field)
    }

    setFilterValues(valuesFiltered?.length > 0 ? valuesFiltered : fieldValues)
  }, [fieldValues, fieldFk, valueFk])

  return (
    <Autocomplete
      size={size}
      disabled={disabled}
      options={filterValues}
      getOptionLabel={(option) => {
        const labelTranslate = `fieldTranslate.${name}.${option.key}`
        const translate = t(labelTranslate)

        return translate === labelTranslate ? option.label : translate
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={(event, value) => onChange(name, value?.key)}
      isOptionEqualToValue={(option, value) => option.key === value?.key}
      value={filterValues?.find(option => option.key === value) || null}
    />
  );
}

export default SelectField;
