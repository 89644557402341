import React, { useState, useEffect } from 'react';
import { ApiFetch } from '../../../Utils/ApiFetch';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { Autocomplete, TextField } from '@mui/material';

export const AutocompleteCell = (props) => {
    const globalContext = useGlobalContext();
    const [optionsAutocomplete, setOptionsAutocomplete] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [open, setOpen] = useState(false);

    const { params, col, entityMode, pkObjectType, remplaceFieldsAutocomplete, fetchThreshold = 4 } = props;

    useEffect(() => {
        if (params.value) {
            setSelectedValue({ value: params.value });
        }
    }, [params.value]);

    const apiFetchAutocomplete = (input, params) => {
        const fetchValue = input.trim() !== '' ? input.trim() : '';

        if (fetchThreshold === 0 || input.length >= fetchThreshold) {
            ApiFetch(
                `autocomplete/${params.tableModuleFieldId}/${entityMode}/${pkObjectType}/${params.tableModuleFieldId}/${params.name}?value=${encodeURIComponent(fetchValue)}`,
                globalContext,
                true,
                async (res) => {
                    if (res.status === 200) {
                        let data = await res.json();
                        setOptionsAutocomplete(data.map(item => ({ value: item.value, data: item })));
                    }
                },
                (error) => {
                    console.log('error /autocomplete', error);
                },
                {
                    method: 'GET'
                }
            );
        } else {
            setOptionsAutocomplete([]);
        }
    };

    useEffect(() => {
        if (open && fetchThreshold === 0) {
            apiFetchAutocomplete('', col);
        }
    }, [open, fetchThreshold, col]);

    return (
        <Autocomplete
            sx={{ width: '100%' }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={optionsAutocomplete}
            getOptionLabel={(option) => option?.value ?? ''}
            value={selectedValue}
            onInputChange={(event, newInputValue) => {
                if (fetchThreshold > 0) {
                    apiFetchAutocomplete(newInputValue, col);
                }
            }}
            onChange={(event, newValue) => {
                const selectedOption = newValue?.value ?? newValue;
                setSelectedValue(newValue);
                params.api.setEditCellValue({ id: params.id, field: params.field, value: selectedOption });
                params.api.commitCellChange({ id: params.id, field: params.field });
                params.api.setCellMode(params.id, params.field, 'view');

                if (newValue?.data) {
                    const { value, ...remplaceField } = newValue.data;
                    remplaceFieldsAutocomplete(params, remplaceField);
                }
            }}
            renderInput={(paramsInput) => (
                <TextField
                    {...paramsInput}
                />
            )}
        />
    );
};
