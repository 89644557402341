import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { constPaginationDataGrid } from '../../constants/paginationDataGrid';
import useGlobalContext from '../../Hooks/useGlobalContext';

export const DataGridOracles = (props) => {
  const { title, rows, columns, type, checkboxSelect, onSelection, selectionModel, loading } = props

  const { t } = useTranslation();
  const defaultPagination = { field: 'Id', sort: 'desc' }
  const [sortModel, setSortModel] = useState([defaultPagination]);
  const {
    pageSizeDGs,
    setPageSizeDGs,
  } = useGlobalContext();

  return (
    <>
      {title && 
        <Box 
          style={{ 
            paddingLeft: '10px', 
            color: '#6d6d6d', 
            fontSize: '0.9rem', 
            marginBottom: 10
          }}
        >
          {title}
        </Box>
      }
      <DataGrid
        className="data-grid-custom-pagination"
        rows={rows}
        columns={columns}
        rowCount={rows.length}
        sortModel={sortModel}
        onSortModelChange={(sortModel) => setSortModel(sortModel)}
        disableRowSelectionOnClick
        paginationMode="client"
        sortingMode="client"
        sx= {{
          height: '475px',
        }}
        isRowSelectable={type === 'assets' && ((params) => 
          params.row.asset_status_change === 'Activo' || 
          params.row.asset_status_change === 'Validado' ||
          params.row.asset_status_change === 'Observado'
        )}
        checkboxSelection={checkboxSelect}
        onSelectionModelChange={(ids) => onSelection && onSelection(ids)}
        selectionModel={selectionModel}
        loading={loading}
        pageSize={pageSizeDGs}
        rowsPerPageOptions={constPaginationDataGrid.defaultPageSizesArray}
        onPageSizeChange={(newPageSize) => {
          setPageSizeDGs(newPageSize)
        }}
        pagination
      />
    </>
  )
}
