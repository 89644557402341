import { Card, Checkbox, ImageListItem, styled, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';

export const AttributeSelectImages = ({ data, tt, isEnable, editMode, enableValues, onChangeFields }) => {
  const theme = useTheme();
  
  const [imagesValues, setImagesValues] = useState([]);

  useEffect(() => {
    setImagesValues(enableValues);
    editMode && onChangeFields(data.label, isEnable, enableValues);
  }, []);

  const onChangeCheck = (key, e) => {
    let values = [];
    const checked = e.target.checked;
    if (checked) {
      values = [...imagesValues, key];
    } else {
      values = imagesValues.filter(img => img !== key);
    }

    setImagesValues(values);
    onChangeFields(data.label, isEnable, values);
  };

  return (
    <>
      <Typography color="primary.main" variant="h3" mb={1}>
        {tt(`qrTemplate.${data.label}`)}
      </Typography>
      {editMode ? (
        data?.value?.map((item) => {
          let StyledCard = styled(Card)`
            border: 1px solid #3969e8;
            box-shadow: none;
            border-radius: 14px;
          `;
          if (item.active === 0) {
            StyledCard = styled(Card)`
              border: 1px solid lightgray;
              box-shadow: none;
              border-radius: 14px;
            `;
          }

          const { key, publicUrl } = item.field 

          return (
            <StyledCard key={key} className="d-flex flex-row w-100 align-center justify-between pt-1 pb-1 pl-2 pr-2 mb-2">
              <Checkbox
                checked={imagesValues.some(imgKey => imgKey === key)}
                onChange={(e) => onChangeCheck(key, e)}
              />
              <div className="d-flex flex-row align-center">
                {key && (
                  <>
                    <Typography variant="body1" component="p" color="text.primary" className="mr-2">
                      {item.label}
                    </Typography>
                    <img
                      style={{ maxWidth: '100px', marginLeft: '20px' }}
                      src={publicUrl}
                      alt="itemImage"
                    />
                  </>
                )}
              </div>
            </StyledCard>
          );
        })
      ) : (
        <Carousel 
          swipe
          navButtonsAlwaysVisible={false}
          navButtonsProps={{ 
            style: {
              backgroundColor: theme?.palette?.text?.primary ?? "grey",
              opacity: 0.5,
              color: theme?.palette?.background?.default ?? 'black'
            }
          }}
          indicatorIconButtonProps={{
            style: {
              color: theme?.palette?.text?.secondary
            }
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: theme?.palette?.text?.primary
            }
          }}
        >
          {data?.value?.map(item => ({
            file: item.field?.publicUrl, 
            label: item.label
          })).map((photo, index) => (
            <ImageListItem
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height:'360px',
                overflow:'hidden'
              }}
            >
             {photo.label && <Typography>{photo.label}</Typography>}
              <img
                src={photo.file}
                style={{
                  height:'360px',
                  objectFit:'contain'
                }}
                alt={'ImageList img'}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </Carousel>
      )}
    </>
  );
};
