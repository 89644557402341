import { useState, useEffect } from 'react'
import {
  Accordion,
  AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    Divider,
    Grid,
    Step,
    StepButton,
    Stepper,
    TextField,
    Typography,
  } from '@mui/material';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LocationField from '../../Components/Generic/Fields/LocationField';
import { ApiFetch } from '../../Utils/ApiFetch';
import useGlobalContext from '../../Hooks/useGlobalContext';
import ModalMessage from '../../Components/Feature/ModalMessage';
import { Autocomplete } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import FileUploader from '../../Components/FileUploader/FileUploader';
import { geocodeAddress } from '../../Helpers/coordinatesFromAddress'
import Loading from '../../Components/Generic/Loading';

const initialState = {
  value:'',
  autocomplete_module_replace_fields:[
    {
      replace_field:'supplier_address_street',
      value:''
    },
    {
      replace_field:'supplier_address_street_number',
      value:''
    },
    {
      replace_field:'supplier_city',
      value:''
    },
    {
      replace_field:'supplier_zip_code',
      value:''
    },
    {
      replace_field:'supplier_location',
      value:''
    },
    {
      replace_field:'supplier_country',
      value:''
    },
    {
      replace_field:'supplier_co2_footprint',
      value:''
    },
    {
      replace_field:'supplier_geolocation',
      value:''
    },
    {
      replace_field:'supplier_picture_1',
      value:''
    },
    {
      replace_field:'supplier_picture_2',
      value:''
    },
    {
      replace_field:'supplier_picture_3',
      value:''
    },
    {
      replace_field:'supplier_picture_4',
      value:''
    }
  ]
}

const LoadSuppliers = (props) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [completed] = useState({});
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [textProp1, setTextProp1] = useState('');
  const [mandatoryFieldsComplete, setMandatoryFieldsComplete] = useState(false);
  const globalContext = useGlobalContext();
  const [waitModal, setWaitModal] = useState(false);
  const [supplierData, setSupplierData] = useState(initialState)
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [isMapLoading, setIsMapLoading] = useState(false)

  useEffect( ()=>{
    setCountries(globalContext.sessionData.models.companyModels.basic.attributes.find(a => a.name === 'l1').values)
  },[globalContext] )

  useEffect( ()=>{
    let country = supplierData.autocomplete_module_replace_fields.find( a => a.replace_field === 'supplier_country' )?.value
    setStates((
      (globalContext.sessionData.models.companyModels.basic.attributes.find(a => a.name === 'l2').values)).filter( a => a.fk === country ))
  },[countries,supplierData,globalContext] )

  useEffect( ()=>{   

    const fetchGeocode = async (fieldsValues, attributes) => {

      let supplierDataCopy = {...supplierData}

      setIsMapLoading(true)

      let geoJSON = await geocodeAddress(fieldsValues, attributes)

      if (geoJSON) {
        const geolocationField = supplierDataCopy.autocomplete_module_replace_fields.find(a => a.replace_field == 'supplier_geolocation');
        geolocationField.value = JSON.stringify(geoJSON);
     }

      setSupplierData(supplierDataCopy)
      setIsMapLoading(false)
    }

    let geoPositioned = supplierData.autocomplete_module_replace_fields.find(a => a.replace_field === 'supplier_geolocation').value

    if(activeStep == 2 && !geoPositioned ){

      const mapKeyValues = { country: "l1", location: "l2", city: "l3", address_street_number: "address_number" }
      let fieldsValues = supplierData.autocomplete_module_replace_fields.map(a => {
         const fieldKey = a.replace_field.replace("supplier_", "");
         return { [mapKeyValues[fieldKey] || fieldKey]: a.value }
      }).reduce((acc, curr) => {
        return { ...acc, ...curr };
      }, {});

      let attributes = [
        {name:'l2',values:states},
        {name:'l1',values:countries}
      ]

      fetchGeocode(fieldsValues, attributes)

    }

  },[activeStep] )

  useEffect( ()=>{
    if(props.supplier){
      setWaitModal(true);    
      ApiFetch(
        `autocomplete/supplier/${props.supplier.id}`,
        globalContext,
        false,
        async (res) => {
          if (res.status === 200) {
            let data = await res.json()
            setSupplierData({value:data.value,autocomplete_module_replace_fields:data.autocomplete_module_replace_fields})
            setWaitModal(false);
          } else {
            setWaitModal(false);
            props.launchToast( t('suppliers.toastMessages.getError') )
          }
        },
        (error) => {
          setWaitModal(false);
          props.launchToast( t('suppliers.toastMessages.getError') )
        },
        {
          method: 'GET'
        }
      );
    }
  },[] )

  const VALID_FORMATS = ['.jpg', '.jpeg', '.png'];

  const STEPS = [
    t('suppliers.steps.S1'),
    t('suppliers.steps.S2'),
    t('suppliers.steps.S3'),
    t('suppliers.steps.S4'),
  ];

  const mandatoryFields = [ 
    'supplier_address_street',
    'supplier_address_street_number',
    'supplier_city',
    'supplier_zip_code',
    'supplier_location',
    'supplier_country',
    'supplier_geolocation',
    'supplier_co2_footprint'
  ]

  const validateMandatoryFields = () => {
    let complete = supplierData.value !== '';
    for (let field of mandatoryFields) {
      const fieldComplete = supplierData.autocomplete_module_replace_fields.find(
        (a) => a.replace_field === field && a.value !== ''
      );
      complete = complete && !!fieldComplete;
    }
    setMandatoryFieldsComplete(complete);
  };
  
  const StyledAccordionSummary = withStyles({
    root: {
      minHeight: 40,
      maxHeight: 40,

      '&.Mui-expanded': {
        minHeight: 40,
        maxHeight: 40,
      },
    },
    expandIcon: {
      order: -1,
    },
  })(AccordionSummary);

  useEffect(() => {
    validateMandatoryFields();
  }, [supplierData]);

  const onChangeMap = (name, val) => {
    val = JSON.parse(val);
    let geodata = JSON.stringify(val)
    getSupplerData('supplier_geolocation',geodata)
  }

  const getUploadProgress = (progressInfo) => {
    setUploadInProgress(progressInfo?.status.loading);
    if (uploadInProgress) {
      setTextProp1('');
    }
  };

  const handleFileOnChange = async (statusInfo) => {
    if (statusInfo.reset) {
      setTextProp1('');
      getSupplerData(`supplier_picture${statusInfo.name}`, '')
    }
    if (statusInfo.success) {
      const currentVal = statusInfo?.uploadedFileInfo[0];
      if (currentVal) {
        getSupplerData(`supplier_picture${statusInfo.name}`,JSON.stringify(currentVal))
      }
    }
  };

  const getSupplerData = (attribute, value) => {

    let data = { ...supplierData }

    if(attribute === 'supplier_value'){
      data.value = value
    }else{
      let exist = data.autocomplete_module_replace_fields.find(a => a.replace_field == attribute)
      if(exist){
        data.autocomplete_module_replace_fields.map( a => {
          if(a.replace_field == attribute){
            a.value = value
          }
        })
      }else{
        data.autocomplete_module_replace_fields.push(
          {replace_field: attribute, value}
        )
      }
    }

    validateMandatoryFields(attribute)
    setSupplierData(data);

  }

  const SaveData = () => {
    setWaitModal(true);
    let options = {
      method: props.supplier ? 'PUT' : 'POST',
      body: JSON.stringify(
        supplierData
      ),
    }
    let url = props.supplier ? `autocomplete/${props.supplier.id}` : 'autocomplete/2'

    ApiFetch(
      url,
      globalContext,
      true,
      (res) => {
        if (res.status === 200) {
          setWaitModal(false);
          setActiveStep(0)
          props.supplier ? props.setEditSupplier(false) : props.setLoadSupplier(false)
          props.reload()
        } else {
          setWaitModal(false);
          props.launchToast(props.supplier ? t('suppliers.toastMessages.putError') : t('suppliers.toastMessages.postError'))
        }
      },
      (error) => {
        setWaitModal(false);
        props.launchToast(props.supplier ? t('suppliers.toastMessages.putError') : t('suppliers.toastMessages.postError'))
      },
      options
    );
    
    props.launchToast( props.supplier ? t('suppliers.toastMessages.put') : t('suppliers.toastMessages.post') )
    props.reload()
  }

  const getValue = (attribute) => supplierData.autocomplete_module_replace_fields.find( (a) => a.replace_field === attribute ? a : false )?.value

  const handleRestoredFile = (index) => {
    let pic = getValue(`supplier_picture${index}`);
    return pic ? JSON.parse(pic) : null
  };

  const getValueAutocomplete = (attribute) => {
    let value = getValue(attribute)
    if(attribute === 'supplier_country') {
      return countries.find( a => a.key === value ) || null
    }
    if(attribute === 'supplier_location') {
      return states.find( a => a.key === value ) || null
    }
  }

  const renderCell = (activeStep) => {
    return (
      <>
        {activeStep === 0 && 
          <>
            <Grid item sx={{ pr: 2 }} xs={12}>
              <TextField
                type="text"
                size="small"
                fullWidth
                required
                label={t('suppliers.formLabel.name')}
                value={ supplierData.value ? supplierData.value : null}
                onChange={(e)=> getSupplerData('supplier_value',e.target.value) }
                InputLabelProps={ supplierData.value ? { shrink: true } : {} }
              />
            </Grid>
            <Grid item sx={{ pr: 2}} xs={12}>
              <TextField
                type="text"
                size="small"
                fullWidth
                required
                label={t('suppliers.formLabel.co2FootPrint')}
                onChange={(e)=>{ getSupplerData('supplier_co2_footprint',e.target.value)}}
                value={ getValue('supplier_co2_footprint') }
                InputLabelProps={ getValue('supplier_co2_footprint') ? { shrink: true } : {} }
              />
            </Grid>
          </>
        }
        {activeStep === 1 && 
          <>
            <Grid item sx={{ pr: 2 }} xs={12}>
              <Autocomplete
                disablePortal
                size="small"
                fullWidth
                options={countries}
                renderInput={(params) => <TextField required {...params} label={t('suppliers.formLabel.country')}/>}
                onChange={(e,value)=>{ getSupplerData('supplier_country',value?.key || '')}}
                value={ getValueAutocomplete('supplier_country')}
                InputLabelProps={ getValue('supplier_country') ? { shrink: true } : {} }
                getOptionLabel={ option => option.label }
              />
            </Grid>
            <Grid item sx={{ pr: 2 }} xs={12}>
              <Autocomplete
                size="small"
                disablePortal
                fullWidth
                options={states}
                renderInput={(params) => <TextField {...params} label={t('suppliers.formLabel.location')}/>}
                onChange={(e,value)=>{ getSupplerData('supplier_location',value?.key || '')}}
                value={ getValueAutocomplete('supplier_location')}
                InputLabelProps={ getValue('supplier_location') ? { shrink: true } : {} }
                getOptionLabel={ option => option.label }
              />
            </Grid>
            <Grid item sx={{ pr: 2 }} xs={12}>
              <TextField
                type="text"
                size="small"
                fullWidth
                required
                label={t('suppliers.formLabel.city')}
                onChange={(e)=>{ getSupplerData('supplier_city',e.target.value)}}
                value={ getValue('supplier_city') }
                InputLabelProps={ getValue('supplier_city') ? { shrink: true } : {} }
              />
            </Grid>
            <Grid item sx={{ pr: 2 }} xs={12}>
              <TextField
                type="text"
                size="small"
                fullWidth
                required
                label={t('suppliers.formLabel.streetName')}
                onChange={(e)=>{ getSupplerData('supplier_address_street',e.target.value)}}
                value={ getValue('supplier_address_street') }
                InputLabelProps={ getValue('supplier_address_street') ? { shrink: true } : {} }
              />
            </Grid>
            <Grid item sx={{ pr: 2 }} xs={12}>
              <TextField
                type="text"
                size="small"
                fullWidth
                required
                label={t('suppliers.formLabel.streetNumber')}
                onChange={(e)=>{ getSupplerData('supplier_address_street_number',e.target.value)}}
                value={ getValue('supplier_address_street_number') }
                InputLabelProps={ getValue('supplier_address_street_number') ? { shrink: true } : {} }
              />
            </Grid>
            <Grid item sx={{ pr: 2 }} xs={12}>
              <TextField
                type="text"
                size="small"
                fullWidth
                required
                label={t('suppliers.formLabel.zipCode')}
                onChange={(e)=>{ getSupplerData('supplier_zip_code',e.target.value)}}
                value={ getValue('supplier_zip_code') }
                InputLabelProps={ getValue('supplier_zip_code') ? { shrink: true } : {} }
              />
            </Grid>
          </>
        }
        {activeStep === 2 && 
          <>
            <Grid item sx={{ pr: 2 }} xs={12}>
              { isMapLoading ? 
                <Loading height={20} size={15}/>
                  :
                <LocationField
                  label={t('suppliers.formLabel.position')}
                  name='GeoData'
                  onChange={onChangeMap}
                  value={getValue('supplier_geolocation') || {}}  
                  modeSatelite={false}
                />
              }
            </Grid>
          </>
        }
        {activeStep === 3 && 
          <>
            <Grid item sx={{ pr: 2 }} xs={12}>
              <Card className="d-flex flex-column flex p-3">
                <Typography variant="h6" component="h4" color="text.light">
                {t('suppliers.formLabel.image1')}
                </Typography>
                <Divider variant="middle" className="mr-0 ml-0 mt-2 mb-2" />
                <Typography
                  variant="caption"
                  component="h6"
                  color="text.light"
                  className="mb-2">
                    {t('suppliers.formLabel.imageLabel')}
                </Typography>
                <FileUploader
                  cancelText={t('component.uploader.cancel')}
                  clearText={t('component.uploader.clear')}
                  defaultText={t('component.uploader.search')}
                  dragDrop={true}
                  getUploadProgress={getUploadProgress}
                  isPrivate={false}
                  maxSize={10}
                  name={'_1'}
                  onChange={handleFileOnChange}
                  restoredFile={handleRestoredFile('_1')}
                  uploadedFileText={textProp1}
                  url={process.env.REACT_APP_UPLOADER_HOST + 'file/upload'}
                  validFormats={VALID_FORMATS}
                />
              </Card>
            </Grid>
            <Grid item sx={{ pr: 2 }} xs={12}>
              <Card className="d-flex flex-column flex p-3">
                <Typography variant="h6" component="h4" color="text.light">
                {t('suppliers.formLabel.image2')}
                </Typography>
                <Divider variant="middle" className="mr-0 ml-0 mt-2 mb-2" />
                <Typography
                  variant="caption"
                  component="h6"
                  color="text.light"
                  className="mb-2">
                    {t('suppliers.formLabel.imageLabel')}
                </Typography>
                <FileUploader
                  cancelText={t('component.uploader.cancel')}
                  clearText={t('component.uploader.clear')}
                  defaultText={t('component.uploader.search')}
                  dragDrop={true}
                  getUploadProgress={getUploadProgress}
                  isPrivate={false}
                  maxSize={10}
                  name={'_2'}
                  onChange={handleFileOnChange}
                  restoredFile={handleRestoredFile('_2')}
                  uploadedFileText={textProp1}
                  url={process.env.REACT_APP_UPLOADER_HOST + 'file/upload'}
                  validFormats={VALID_FORMATS}
                />
              </Card>
            </Grid>
            <Grid item sx={{ pr: 2 }} xs={12}>
              <Card className="d-flex flex-column flex p-3">
                <Typography variant="h6" component="h4" color="text.light">
                {t('suppliers.formLabel.image3')}
                </Typography>
                <Divider variant="middle" className="mr-0 ml-0 mt-2 mb-2" />
                <Typography
                  variant="caption"
                  component="h6"
                  color="text.light"
                  className="mb-2">
                    {t('suppliers.formLabel.imageLabel')}
                </Typography>
                <FileUploader
                  cancelText={t('component.uploader.cancel')}
                  clearText={t('component.uploader.clear')}
                  defaultText={t('component.uploader.search')}
                  dragDrop={true}
                  getUploadProgress={getUploadProgress}
                  isPrivate={false}
                  maxSize={10}
                  name={'_3'}
                  onChange={handleFileOnChange}
                  restoredFile={handleRestoredFile('_3')}
                  uploadedFileText={textProp1}
                  url={process.env.REACT_APP_UPLOADER_HOST + 'file/upload'}
                  validFormats={VALID_FORMATS}
                />
              </Card>
            </Grid>
            <Grid item sx={{ pr: 2 }} xs={12}>
              <Card className="d-flex flex-column flex p-3">
                <Typography variant="h6" component="h4" color="text.light">
                  {t('suppliers.formLabel.image4')}
                </Typography>
                <Divider variant="middle" className="mr-0 ml-0 mt-2 mb-2" />
                <Typography
                  variant="caption"
                  component="h6"
                  color="text.light"
                  className="mb-2">
                    {t('suppliers.formLabel.imageLabel')}
                </Typography>
                <FileUploader
                  cancelText={t('component.uploader.cancel')}
                  clearText={t('component.uploader.clear')}
                  defaultText={t('component.uploader.search')}
                  dragDrop={true}
                  getUploadProgress={getUploadProgress}
                  isPrivate={false}
                  maxSize={10}
                  name={'_4'}
                  onChange={handleFileOnChange}
                  restoredFile={handleRestoredFile('_4')}
                  uploadedFileText={textProp1}
                  url={process.env.REACT_APP_UPLOADER_HOST + 'file/upload'}
                  validFormats={VALID_FORMATS}
                />
              </Card>
            </Grid>
          </>
        }
      </>
    )
  }

  return(
    <Grid container justifyContent="center" alignItems="center">
      <ModalMessage open={waitModal} toggleModal={() => {}} modalText={t("component.processing")} />
      <Grid item xs={12} className="mt-2">
        <Stepper nonLinear activeStep={activeStep}>
          {STEPS.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton
                color="inherit"
                onClick={() => {
                  setActiveStep(index);
                }}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Divider variant="middle" sx={{ mt: 4, mb:2 }} />
      </Grid>
      {activeStep === 0 && 
        <Accordion  
          style={{
            marginBottom: '10px',
            marginRight: '10px',
            borderRadius: '5px',
            width: '100%'
          }}
          sx={{ 
            marginTop: 1 
          }}
          defaultExpanded={true}
        >
          <StyledAccordionSummary
            style={{ 
              height: '40px', 
            }}
            expandIcon={
              <FontAwesomeIcon icon={faChevronDown} />
            }
          >
            <Typography
              variant="subtitle1"
              component="h3"
              color="primary">
              &nbsp;
              {STEPS[0]}
              &nbsp;
            </Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} marginBottom={2}>
              {
                renderCell(0)
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      }
      {activeStep === 1 && 
        <Accordion  
          style={{
            marginBottom: '10px',
            marginRight: '10px',
            borderRadius: '5px',
            width: '100%'
          }}
          sx={{ 
            marginTop: 1 
          }}
          defaultExpanded={true}
        >
          <StyledAccordionSummary
            style={{ 
              height: '40px', 
            }}
            expandIcon={
              <FontAwesomeIcon icon={faChevronDown} />
            }
          >
            <Typography
              variant="subtitle1"
              component="h3"
              color="primary">
              &nbsp;
              {STEPS[1]}
              &nbsp;
            </Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} marginBottom={2}>
              {
                renderCell(1)
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      }
      {activeStep === 2 && 
        <Accordion  
          style={{
            marginBottom: '10px',
            marginRight: '10px',
            borderRadius: '5px',
            width: '100%'
          }}
          sx={{ 
            marginTop: 1 
          }}
          defaultExpanded={true}
        >
         <StyledAccordionSummary
            style={{ 
              height: '40px', 
            }}
            expandIcon={
              <FontAwesomeIcon icon={faChevronDown} />
            }
         >
          <Typography
            variant="subtitle1"
            component="h3"
            color="primary">
            &nbsp;
            {STEPS[2]}
            &nbsp;
          </Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} marginBottom={2}>
              {
                renderCell(2)
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      }
      {activeStep === 3 && 
        <Accordion  
          style={{
            marginBottom: '10px',
            marginRight: '10px',
            borderRadius: '5px',
            width: '100%'
          }}
          sx={{ 
            marginTop: 1 
          }}
          defaultExpanded={true}
        >
        <StyledAccordionSummary
          style={{ 
            height: '40px', 
          }}
          expandIcon={
            <FontAwesomeIcon icon={faChevronDown} />
          }
        >
          <Typography
            variant="subtitle1"
            component="h3"
            color="primary">
            &nbsp;
            {STEPS[3]}
            &nbsp;
          </Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} marginBottom={2}>
              {
                renderCell(3)
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      }
      {activeStep >= 0 &&
        <Grid container justifyContent="center" alignItems="center">
          <Button
            sx={{ ml: 1 }}
            variant="outlined"
            onClick={ () => activeStep === 0 ? 
              ( props.supplier ? 
                  props.setEditSupplier(false) : 
                  props.setLoadSupplier(false)
              ) : 
              setActiveStep( activeStep - 1 ) }
          >
            {t('stepByStep.button.back')}
            </Button>
          {activeStep < 3 && 
            <Button
              sx={{ ml: 1 }}
              variant="contained"
              onClick={ () => setActiveStep( activeStep + 1 ) }  
            >
              {t('stepByStep.button.next')}
            </Button>
          }
          {activeStep === 3 && 
            <Button
              sx={{ ml: 1 }}
              variant="contained"
              onClick={()=>SaveData()}
              disabled={!mandatoryFieldsComplete} 
            >
              {t('suppliers.formLabel.loadButton')}
            </Button>
          }
        </Grid>
      }
    </Grid>
  )
}

export default LoadSuppliers