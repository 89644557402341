import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  InputAdornment,
  Card,
} from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import useGlobalContext from '../../Hooks/useGlobalContext';
import ModalMessage from '../../Components/Feature/ModalMessage';

import { ApiFetch } from '../../Utils/ApiFetch';
import { StyledImageSeparator } from '../../pages/Transfers/TransferBoxTheme';
import { useTranslation } from 'react-i18next';


const BoxTarget = (props) => {
  const { t } = useTranslation();
  const [boxTarget, setBoxTarget] = useState(0);
  const [boxTargetObject, setBoxTargetObject] = useState({});
  const [assetsError, setAssetsError] = useState({});
  const [boxAlias, setBoxAlias] = useState("");
  const [waitModal, setWaitModal] = useState(false);
  const [showNotFount, setShowNotFound] = useState(false);
  const [messageError, setMessageError] = useState(null);
  const [searchLabel, setSearchLabel] = useState(t("stepByStep.button.transfer.validate"));
  const [mdsBox, setMdsBox] = useState({
    renspa: "-",
    cuig: "-",
    company: "-",
    l1: "-",
    l2: "-",
    l3: "-",
    logo:"-",
  });
  const { assetsList, eventType}  = props
  const globalContext = useGlobalContext();
  const { selectedBox, selectedAsset } = globalContext;

  useEffect(()=>{
    if (props.value !== '' && props.value !== undefined) {
      let valueObj = JSON.parse(props.value)
      setBoxTarget(valueObj?.id);
      setBoxTargetObject(valueObj);
    }
  },[props.value])

  useEffect(() => {
    if (props.mode) {
      if (props.mode==='dynamicForm') {
        setSearchLabel(t("stepByStep.button.search"));
      }
    }
  }, [props.mode]);

  const searchBoxFromAlias = () => {
    setWaitModal(true);
    setMessageError(null)
    setShowNotFound(false);
    ApiFetch(
      'boxes/fromAlias/'+boxAlias+'/'+selectedBox.id+'/'+selectedAsset.id+'/'+eventType ?? 1000,
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          let data = await res.json();

          if (!data.error && data.length > 0) {
            setBoxTarget(data[0].id);
            setBoxTargetObject(data[0]);
            data[0].error ? setAssetsError(data[0].error) : setAssetsError([])

            if (props.mode) {
              if (props.mode==='dynamicForm' && !data[0].error) {
                props.onBoxSelected(JSON.stringify(data[0]))
              }
            }

            if (data[0].mds) {
              let obj = {};
              for (let item of data[0].mds) {
                  obj[item.field_key]=item.field_value;

                  if (item.field_value!=null && item.field_value!=="") {
                    try {
                      let file = JSON.parse(item.field_value);
                      if (file?.originalName) {
                        let originalNameSplit = file.originalName.split(".");
                        if (['JPG','PNG','JPEG','TIFF','BMP'].includes(originalNameSplit[originalNameSplit.length-1].toUpperCase())) {
                          let image=<img style={{maxWidth:"200px"}} src={file.publicFileURI} alt=""></img>
                          obj[item.field_key]=image;
                        }
                      }
                    } catch (e) {
                      // no parsea
                    }
                  }
              }

              setMdsBox(obj);
            }
          } else {
            if (props.mode) {
              if (props.mode==='dynamicForm') {
                props.onBoxSelected('')
              }
            }

            setBoxTarget(0);
            setMessageError(data?.error)
            setShowNotFound(true);
          }

          setWaitModal(false);
        } else {
          setWaitModal(false);
        }
      },
      (error) => {
        setWaitModal(false);
      },
      {
        method: 'POST',
        body: JSON.stringify({
          assetsList
        }),
      }
    );
  }

  return (
    <>
      <ModalMessage open={waitModal} toggleModal={() => {}} modalText={t("component.processing")} />
      <>
      <Grid item xs={12} className="mt-1">
        {props.label}
        <Typography variant="caption" component="p" className='ml-2'>
          {t("stepByStep.massive.target.title")}
        </Typography>
        <div className="d-flex align-center ">
          <TextField
            id="filled-search"
            type="search"
            className="mr-2"
            fullWidth
            value={boxAlias}
            onChange={(val) => setBoxAlias(val.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} color="#61B15A" />
                </InputAdornment>
              ),
            }}
          />
          <Button disabled={!boxAlias} onClick={()=>{searchBoxFromAlias();}} variant="contained">
            { searchLabel }
          </Button>
        </div>
        {(showNotFount) &&
          <div className="d-flex align-center mt-2" style={{color:"red"}}>
              { t(`stepByStep.massive.target.${messageError ? messageError : 'msgNoFacility'}`) }
          </div>
        }
      </Grid>
      {(boxTarget!==0 && boxTarget) &&
      <>
        <Grid item xs={12} className="mt-3">
          <Grid component={Card} container>
            <Grid item xs={9} className="py-3 px-4">
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="body1" component="p" color="text.primary" className="mb-2">
                    { t("stepByStep.massive.target.facility") }
                  </Typography>
                  <Typography variant="body1" component="p" color="text.primary">
                    { t("stepByStep.massive.target.company") }
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" component="p" className="mb-2">
                    <b>{boxTargetObject?.box_name?boxTargetObject.box_name:"-"}</b>
                  </Typography>
                  <Typography variant="body1" component="p">
                  <b>{boxTargetObject?.company?boxTargetObject.company.company_name:"-"}</b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {mdsBox.logo &&
              <StyledImageSeparator item xs={3} className="p-0" >
              {mdsBox.logo}
              </StyledImageSeparator>
            }

            {(assetsError.length > 0) &&
              <div
                className="d-flex flex-column align-content-start pl-3 py-2 col-12"
                style={{
                  maxHeight:'4.8rem',
                  overflowY: 'auto',
                  color:"red",
                  borderTop: '2px solid #e9ecef'
                }}
              >
                {assetsError.map((error, i) =>
                  <Typography className="m-0" variant="caption">
                    {
                      (error.assetExist && error.model === '35') ? `
                        ${t("stepByStep.massive.target.error.text.msgError1")} "${error.asset.id_asset}"
                        ${t("stepByStep.massive.target.error.text.msgError2")} "${t(`stepByStep.massive.target.error.establishment.${error.boxSender}`)}"
                        ${t("stepByStep.massive.target.error.text.msgError3")} "${t(`stepByStep.massive.target.error.establishment.${error.boxReceiver}`)}",
                        ${t("stepByStep.massive.target.error.text.msgError4")} "${t(`stepByStep.massive.target.error.state.${error.asset.state}`)}"
                      ` : (error.assetExist && error.model === '37') ? `
                        ${t("stepByStep.massive.target.error.text.msgError1")} "${error.asset.id_asset}"
                        ${t("stepByStep.massive.target.error.text.msgError2")} "${t(`stepByStep.massive.target.error.establishment.${error.boxSender}`)}"
                        ${t("stepByStep.massive.target.error.text.msgError3")} "${t(`stepByStep.massive.target.error.establishment.${error.boxReceiver}`)}",
                        ${t("stepByStep.massive.target.error.text.msgError5")}
                      ` : `
                        ${t("stepByStep.massive.target.error.assetNonExist")}
                      `
                    }
                  </Typography>
                )}
              </div>
            }
          </Grid>
        </Grid>
        <>
          <Grid item xs={12} className="d-flex justify-center">
            {(props.mode !=='dynamicForm' && assetsError.length <= 0) &&
              <Button type="button" onClick={()=>{props.onBoxSelected(JSON.stringify(boxTargetObject))}} sx={{ mr: 1 }} variant="contained">
                  { t("stepByStep.button.next") }
              </Button>
            }
          </Grid>
        </>
      </>
     }
    </>
    </>
  );
};

export default BoxTarget;
