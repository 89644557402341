import React from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Button
} from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useTranslation } from 'react-i18next';

const DelegationAccordion = ({ title, content, onClick, id, ariaControls, expanded}) => {
  const { t } = useTranslation();

  return (
    <Accordion
      defaultExpanded={expanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h5>{title}</h5>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {content.map((data) => (
            <Grid item xs={12} lg={6} xl={4} key={data.type + "-" + data.origin + "-" + data.id}>
              <Card sx={{ width: '100%', height: '100%' }}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe">
                      {data.name && data?.name[0]?.toUpperCase()}
                    </Avatar>
                  }
                  title={
                    <Typography
                      noWrap
                      variant="h6"
                      sx={{
                        cursor: data?.origin !== "COMPANY_DELEGATE" ? 'pointer' : 'alias',
                        maxWidth: '250px',
                      }}
                      color="primary"
                      onClick={() => {
                        if (data?.origin !== "COMPANY_DELEGATE") onClick(data);
                      }}
                    >
                      {data?.name}
                    </Typography>
                  }
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {"Origen"} {data?.origin}
                  </Typography>
                  <Typography variant="caption" component="p" color="text.light">
                    {data?.delegated_by_user_name && (
                      <p>
                        {t("stepByStep.msg.delegatedBy")} {data.delegated_by_user_name} (
                        {data.delegated_by_user_email})
                      </p>
                    )}
                  </Typography>
                  {(data?.origin !== "OWNER" && data.user_owner_name) && (
                    <Typography variant="caption" color="text.light">
                      {t("stepByStep.msg.owner")} {data.user_owner_name}
                    </Typography>
                  )}
                  {data?.origin === "COMPANY_DELEGATE" && (
                    <div>
                      <Button variant="contained" onClick={() => onClick(data)}>
                        {t("component.popoverOption.delete")}
                      </Button>
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}

          {content.length === 0 && (
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              <div>{t("stepByStep.msg.noDelegations")}</div>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default DelegationAccordion