import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { toast, ToastContainer } from 'material-react-toastify';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { submitDeleteInfo, submitDetailInfo } from '../../../services/object';
import { ApiFetch } from '../../../Utils/ApiFetch';
import ConfirmDialog from '../../Feature/ConfirmDialog';
import 'material-react-toastify/dist/ReactToastify.css';

const SRA = ({
  fieldsValues,
  fieldsLabels,
  fields,
  formProps,
  onChange,
  disabled,
  name,
  label,
}) => {
  const globalContext = useGlobalContext();
  const { userData } = useGlobalContext();
  const { t } = useTranslation();
  const [menuActionSelectedRow, setMenuActionSelectedRow] = useState(-1);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [resultSRA, setResultSRA] = useState({
    data: [],
    error: {
      value: false,
      message: '',
    },
  });
  const [loading, setLoading] = useState(true);
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    alertText: '',
  });

  const [formData, setFormData] = useState({
    mode: 'hba',
    hba: null,
    cc: null,
    rp: null,
    category: {},
    breed: {},
  });

  useEffect(() => {
    if (menuActionSelectedRow !== -1) {
      let values = fieldsValues[name]
      if (values !== '') {
        setConfirmOpen(true);
      } else {
        handleOnConfirm();
        setShowForm(false);
      }
    }
  }, [menuActionSelectedRow]);



  const handleChange = (e) => {
    setFormData((current) => ({
      ...current,
      [e.target.name]: e.target.value.trim(),
    }));
  };

  const handleSearch = async (e) => {

    setMenuActionSelectedRow(-1);

    setAlertInfo((current) => ({
      ...current,
      show: false,
      alertText: '',
    }));

    if (formData.mode === 'hba' && !formData.hba) {
      setAlertInfo((current) => ({
        ...current,
        show: true,
        alertText: 'Se debe ingresar HBA',
      }));
    } else if (formData.mode === 'rp' && (!formData.cc || !formData.cc)) {
      setAlertInfo((current) => ({
        ...current,
        show: true,
        alertText: 'Se debe ingresar Código de Criador y RP',
      }));
    } else if (!fieldsValues.category || !fieldsValues.breed) {
      setAlertInfo((current) => ({
        ...current,
        show: true,
        alertText: 'Se debe ingresar raza y categoría',
      }));
    } else {
      setShowForm(true);
      setLoading(true);
      const searchSRA = {
        mode: formData.mode,
        category: {
          value: fieldsLabels.category,
          label: fieldsLabels.category,
        },
        breed: {
          value: fieldsLabels.breed,
          label: fieldsLabels.breed,
        },
        userId: parseInt(userData.userData.id) || 0,
      };

      if (formData.mode === 'hba') {
        searchSRA.hba = parseInt(formData.hba) || 0;
      } else {
        searchSRA.cc = parseInt(formData.cc) || 0;
        searchSRA.rp = parseInt(formData.rp) || 0;
      }

      await ApiFetch(
        'api/genealogy/sra',
        globalContext,
        true,
        async (body) => {
          const response = await body.json();

          const reducedResponse = response.map((item, index) => ({
            ...item,
            id: index,
          }));
          setResultSRA((current) => ({
            ...current,
            data: reducedResponse,
            error: {
              value: false,
              message: '',
            },
          }));
          setLoading(false);
        },
        async (error) => {
          const err = await error.json();
          setResultSRA((current) => ({
            ...current,
            data: [],
            error: {
              value: true,
              message: err.error,
            },
          }));
          setLoading(false);
        },
        {
          method: 'POST',
          body: JSON.stringify(searchSRA),
        }
      );
    }
  };

  const rowMenuActionsHandleClick = (event, row) => {
    setMenuActionSelectedRow(row.id);
  };

  const renderActionButtons = (row) => {
    return (
      <Button
        variant="contained"
        size="small"
        sx={{ margin: '2rem' }}
        onClick={(event) => rowMenuActionsHandleClick(event, row)}>
        Seleccionar
      </Button>
    );
  };

  const columnsDef = [
    { field: 'id', headerName: 'Id', hide: true },
    { field: 'Nombre', headerName: 'Nombre', flex: 1 },
    { field: 'Sexo', headerName: 'Sexo', flex: 1 },
    { field: 'HBA', headerName: 'HBA', flex: 1 },
    { field: 'fechaNacimiento', headerName: 'fechaNacimiento', flex: 1 },
    {
      field: ' ',
      headerName: ' ',
      flex: 1,
      renderCell: (params) => renderActionButtons(params),
    },
  ];

  const successCallback = (response) => {
    const afected = response?.afected || response?.length;
    const translateText = response?.afected
      ? 'afectedRowsAmount'
      : 'afectedRows';
    toast.success(t(translateText, { amount: afected }), {
      className: 'toast-message-body',
      progressClassName: 'toast-message-progress',
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    onChange();
  };

  const errorCallback = (error) => {
    toast.error(t('detailError'), {
      className: 'toast-message-body',
      progressClassName: 'toast-message-progress',
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const deletePreviusDetails = async () => {
    const previusDetails = fieldsValues[name];
    if (previusDetails) {
      const parsedDetails = JSON.parse(previusDetails);
      const detailsId = parsedDetails.map((item) => item.id);
      const detailInfo = {
        errorCallback,
        fields,
        formProps,
        globalContext,
        successCallback,
        values: detailsId,
      };
      await submitDeleteInfo(detailInfo);
    }
  };

  const handleOnConfirm = async () => {
    await deletePreviusDetails();
    const currentRow = resultSRA.data.find(
      (item) => item.id === menuActionSelectedRow
    );
    if (currentRow) {
      const detailInfo = {
        errorCallback,
        fields,
        formProps,
        globalContext,
        successCallback,
        values: currentRow,
      };
      await submitDetailInfo(detailInfo);
      setShowForm(false);
    }
  };

  function CustomNoRowsOverlay() {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '85px',
        }}>
        {t('No existen registros genealógicos para el criterio buscado')}
      </div>
    );
  }

  return (
    <Paper sx={{ padding: '1rem' }}>
      <ToastContainer />
      <Box component="form">
        <h6>Podés completar tus datos genealógicos consultando la base de datos de la <b>Sociedad Rural Argentina</b></h6>
        {alertInfo.show && (
          <span className="d-flex flex-row align-center">
            <Alert severity="error" sx={{ width: '100%', margin: '1rem 0rem' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: alertInfo.alertText,
                }}></div>
            </Alert>
          </span>
        )}
        <RadioGroup
          aria-labelledby="radio-buttons-type-group-label"
          defaultValue="hba"
          name="radio-buttons-typ"
          row>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              value="hba"
              control={<Radio size="small" />}
              label=""
              name="mode"
              onChange={handleChange}
              labelPlacement="bottom"
            />
            <TextField
              size="small"
              variant="outlined"
              disabled={disabled || formData.mode !== 'hba'}
              autoFocus
              name="hba"
              placeholder="HBA"
              onChange={handleChange}
            />
            <FormControlLabel
              value="rp"
              control={<Radio size="small" />}
              label=""
              name="mode"
              onChange={handleChange}
              labelPlacement="bottom"
            />
            <TextField
              size="small"
              variant="outlined"
              disabled={disabled || formData.mode !== 'rp'}
              name="cc"
              placeholder="Código de Criador"
              onChange={handleChange}
            />
            <TextField
              size="small"
              disabled={disabled || formData.mode !== 'rp'}
              name="rp"
              placeholder="RP"
              variant="outlined"
              onChange={handleChange}
            />
            <Button style={{ height: "30px !important;" }} disabled={disabled} variant="outlined" onClick={handleSearch}>
              Buscar
            </Button>
          </Stack>
        </RadioGroup>
      </Box>

      <Dialog
        open={showForm}
        onClose={() => { }}
        fullWidth={true}
        maxWidth={'lg'}>
        <DialogTitle className="text-center mt-4 mb-4">
          {loading && (
            <p>
              Esperando datos de <b>Sociedad Rural Argentina</b>
            </p>
          )}
          {!loading && (
            <p>
              Resultado de la búsqueda con el criterio seleccionado en la base
              de <b>Sociedad Rural Argentina</b>
            </p>
          )}
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {resultSRA.error.value === true && <>{resultSRA.error.message}</>}
              {resultSRA.data && resultSRA.error.value !== true && (
                <>
                  <DataGrid
                    style={{
                      backgroundColor: '#f9f9f9',
                      minHeight: '270px',
                    }}
                    rows={resultSRA.data}
                    columns={columnsDef}
                    pagination

                    autoHeight
                    pageSize={5}
                    rowHeight={35}
                    headerHeight={35}
                    hideFooter={true}

                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                  />
                </>
              )}
            </>
          )}
        </DialogContent>
        {!loading && (
          <div className="text-center p-2">
            <Button
              variant="contained"
              size="sm"
              type="button"
              onClick={() => setShowForm(false)}>
              Cancelar
            </Button>
          </div>
        )}
      </Dialog>
      <ConfirmDialog
        title={t('titleDialogDetail', { fieldType: label })}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onClose={() => (setMenuActionSelectedRow(-1))}
        onConfirm={handleOnConfirm}>
        {t('confirmDialogDetail', { fieldType: label })}
      </ConfirmDialog>
    </Paper>
  );
};
export default SRA;
