import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getTopBarItems } from '../../services/sideBar';
import { useTranslation } from 'react-i18next';
import useGlobalContext from '../../Hooks/useGlobalContext';

export const FiltersMetrics = (props) => {
    const { setSelectedEstablishment, setSelectedQrType, topBar, getQRsMetrics, selectedEstablishment, selectedQrType } = props
    
    const { t } = useTranslation();
    const { selectedAsset } = useGlobalContext();
    
    const [loading, setLoading] = useState(false);

    const [selectedChain, setSelectedChain] = useState(topBar?.chain?.id ?? '');
    const [selectedActive, setSelectedActive] = useState(topBar?.active?.id ?? '');
    const [selectedCompany, setSelectedCompany] = useState(topBar?.company?.id ?? '');

    const [allChains, setAllChains] = useState([]);
    const [allActives, setAllActives] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);
    const [allEstablishments, setAllEstablishments] = useState([]);

    useEffect(() => {
        setLoading(true)
        getTopBarItems()
        .then(res => {
            setAllChains(res.data.suplyChain)
            setAllActives(res.data.actives)
            setAllCompanies(res.data.companies)
            setAllEstablishments(res.data.establishments)
        })
        .catch(err => console.log('error gettopBarItems', err))
        .finally(() => setLoading(false))
        getQRsMetrics()
    }, [])

    const HandleOnClickChain = (chainId) => {
        setSelectedEstablishment()
        setSelectedCompany()
        setSelectedActive()
        setSelectedChain(chainId)
    };

    const HandleOnClickActives = (activeId) => {
        setSelectedEstablishment()
        setSelectedCompany()
        setSelectedActive(activeId)
    };

    const HandleOnClickCompanies = (companyId) => {
        setSelectedEstablishment()
        setSelectedCompany(companyId)
    };

    const HandleOnClickEstablishment = async (establishmentId) => {
        setSelectedEstablishment(establishmentId)
    }

    const HandleOnQrType = async (qrTypeId) => {
        setSelectedQrType(qrTypeId)
    }

    return (
        <>
            <Grid container spacing={1} sx={{ pr: 2, mb: 2 }} justifyContent='flex-end'>
                <Grid item xs={3}>
                    <FormControl fullWidth sx={{color: '#666666', mb:1}}>
                        <InputLabel size="small" id="select-label-chain">{t('topBar.chain')}</InputLabel>
                        <Select
                            disabled={false}
                            name="chain"
                            label={t('topBar.chain')}
                            size="small"
                            labelId="select-label-chain"
                            value={selectedChain}
                            placeholder="Seleccione Cadena de Suministros"
                            onChange={(e) => HandleOnClickChain(e.target.value)}
                        >   
                            {allChains.map((itemChain) => (
                                <MenuItem value={itemChain.id} key={itemChain.id}>{itemChain.value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth sx={{color: '#666666', mb:1}}>
                        <InputLabel size="small" id="select-label-active">{t('topBar.active')}</InputLabel>
                        <Select
                            disabled={false}
                            name="active"
                            label={t('topBar.active')}
                            size="small"
                            labelId="select-label-active"
                            value={selectedActive}
                            placeholder="Seleccione Activo"
                            onChange={(e) => HandleOnClickActives(e.target.value)}
                        >
                            {allActives.filter(active => active.fk === selectedChain).map((itemChain) =>
                                <MenuItem value={itemChain.id} key={itemChain.id}>{itemChain.value}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth sx={{color: '#666666', mb:1}}>
                    <InputLabel size="small" id="select-label-company">{t('topBar.company')}</InputLabel>
                    <Select
                        disabled={false}
                        name="company"
                        label={t('topBar.company')}
                        size="small"
                        labelId="select-label-company"
                        value={selectedCompany}
                        placeholder="Seleccione Compañia"
                        onChange={(e) => HandleOnClickCompanies(e.target.value)}
                    >
                        {allCompanies
                            .filter(company => company.fk === selectedActive)
                            .map((itemChain) =>
                            <MenuItem value={itemChain.id} key={itemChain.id}>{itemChain.value}</MenuItem>)
                        }
                    </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth sx={{color: '#666666', mb:1}}>
                    <InputLabel size="small" id="select-label-1">{t('topBar.establishment')}</InputLabel>
                    <Select
                        disabled={false}
                        name="establishment"
                        label={t('topBar.establishment')}
                        size="small"
                        labelId="select-label-establishment"
                        value={selectedEstablishment}
                        placeholder="Seleccione Establecimiento"
                        onChange={(e) => HandleOnClickEstablishment(e.target.value)}
                    >
                        {allEstablishments
                            .filter(establishment => establishment.fk === selectedCompany && establishment.asset_type === selectedActive)
                            .map((itemChain) =>
                            <MenuItem value={itemChain.id} key={itemChain.id}>{itemChain.value}</MenuItem>)
                        }
                    </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth sx={{color: '#666666', mb:1}}>
                    <InputLabel size="small" id="select-label-qrtype">{t('topBar.qrType')}</InputLabel>
                    <Select
                        disabled={false}
                        name="qrType"
                        label={t('topBar.qrType')}
                        size='small'
                        labelId="select-label-qrtype"
                        value={selectedQrType}
                        placeholder="Seleccione Tipo de QR"
                        onChange={(e) => HandleOnQrType(e.target.value)}
                    >
                        <MenuItem value="qrIdenteco">Identeco</MenuItem>
                        <MenuItem value="qrIdentecoTrupo">Identeco Trupo</MenuItem>
                        {(selectedAsset.id === "33" || selectedAsset.id === "34") && <MenuItem value="qrProduktoTrupo">Produkto Trupo</MenuItem>}
                        {(selectedAsset.id === "37") &&<MenuItem value="qrBlendingTrupo">Blending Trupo</MenuItem>}
                    </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Box style={{display: 'flex', justifyContent: 'flex-end' }} my={1}>
                <Button variant="outlined" disabled={loading} onClick={getQRsMetrics}>
                    {t("qrMetrics.search")} 
                </Button>
            </Box>
        </>
    )
}
