import { Typography, useTheme } from '@mui/material';
import { TrazeTable } from './TrazeTable';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const AttributeTable = ({ data, tt }) => {
  const theme = useTheme()

 return (
    <>
        <Accordion >
          <AccordionSummary
            disabled={data.value.values.length===0} // TODO hay un chequo antes, no debería llegar acá
            expandIcon={<ExpandMoreIcon sx={{fontSize:"2rem"}}/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{flexDirection:'row-reverse', border:'0px', background: theme?.palette?.background?.default ?? 'white'}}
          >
            <Typography color='primary.main' variant='h3' className='pt-1' style={{marginBottom:'0.5rem'}}>
              { ` ${tt(data.label.toUpperCase())}` }
            </Typography>
            
          {/* TODO hay un chequo antes, no debería llegar acá */}
          {/* =================================== */}
            { data.value.values.length===0 &&
            <Typography color='secondary.contrastText' variant='subtitle1' className='pt-1' style={{marginBottom:'0.5rem'}}>
              &nbsp;&nbsp;(no hay registros)
            </Typography>
          }
          </AccordionSummary>
           <AccordionDetails
            style={{flexDirection:'row-reverse', border:'0px', background: theme?.palette?.background?.default ?? 'white'}}
            >
            <TrazeTable tt={tt} data={data} theme={theme}/> 
          </AccordionDetails> 
        </Accordion>
    </>
 );
};
