import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { QrTemplate } from '../components';
import { getQrBrewingTrupo, postQrBrewingTrupo } from '../../../services/qr';

export const QrBrewingTrupo = () => {
  const { id } = useParams();
  const [attributes, setAttributes] = useState()
  const [qrModelTheme, setQrModelTheme] = useState(null)
  const [qrModelLogo, setQrModelLogo] = useState(null)
  const [qrModelLogoWoText, setQrModelThemeWoText] = useState(null)
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(false)

  useEffect(() => {
    getQrBrewingTrupo(id)
      .then(res => {
        if (res.status === 206) {
          setErrorMessage(res.data)
        } else { 
          setAttributes(res.data.attributes) 
          setQrModelTheme(res.data.theme?.theme?.blueTheme)
          setQrModelLogo(res.data.theme?.logo?.url)
          setQrModelThemeWoText(res.data.theme?.logoWoText?.url)
        }
      })
      .catch(err => {
        console.log('Error getQrBrewingTrupo', err)
        setErrorMessage(true)
      })
    postQrBrewingTrupo(id)
  }, [id]);

  return (
    <QrTemplate
      attributes={attributes}
      qrModelTheme={qrModelTheme}
      qrModelLogo={qrModelLogo}
      qrModelLogoWoText={qrModelLogoWoText}
      editMode={false}
      errorMessage={errorMessage ? errorMessage : ''}
    />
  )
}
