import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Card,
  Button,
  Typography,
  Divider,
  Popover,
  Link,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import FileUploader from './FileUploader';
import useGlobalContext from '../../Hooks/useGlobalContext';
import Icon from '@mui/material/Icon';

import { ASSETS_MANUAL_LOAD } from '../../constants/paths';
import { useTranslation } from 'react-i18next';

function LoadFile(props) {
  const { t } = useTranslation();
  let history = useHistory();
  const globalContext = useGlobalContext();
  const EXCEL_ALTA_MASIVA = require(`../../Repository/${globalContext.selectedAsset.id}/alta_masiva.xlsx`)
  const [textProp1, setTextProp1] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);

  // For file tooltip
  const [newFileTooltip, setNewFileTooltip] = React.useState(null);
  const newFileTooltipOpen = Boolean(newFileTooltip);
  const showNewFileTooltip = (event) => {
    setNewFileTooltip(event.currentTarget);
  };
  const closeNewFileTooltip = () => {
    setNewFileTooltip(null);
  };
  const NewFileTooltipId = newFileTooltipOpen ? 'new-file-popover' : undefined;

  const getUploadProgress = (progressInfo) => {
    setUploadInProgress(progressInfo?.status.loading);
    if (uploadInProgress) {
      setTextProp1('');
    }
  };

  const handleOnChange = async (statusInfo) => {
    if (statusInfo.reset) {
      setTextProp1('');
    }
    if (statusInfo.success) {
      const currentVal = statusInfo?.uploadedFileInfo[0];
      if (currentVal) {
        await props.onUploadFile(currentVal);
      }
    }
  };

  return (
    //Starts the route view with <Grid container> and add <Grid item 12> for rows.
    <Grid container spacing={2}>
      {!props.hideUploadExcel && 
        <Grid item xs={6} xl={3} className="d-flex">
          <Card className="d-flex flex-column flex p-3">
            <Typography variant="h6" component="h4" color="text.light">
              {t('stepByStep.massive.step1.uploadFile')}
            </Typography>
            <Divider variant="middle" className="mr-0 ml-0 mt-2 mb-2" />
            <Typography
              variant="caption"
              component="h6"
              color="text.light"
              className="mb-2">
              {t('stepByStep.massive.step1.uploadFileSubtitle')}
            </Typography>
            <FileUploader
              cancelText={t('component.uploader.cancel')}
              clearText={t('component.uploader.clear')}
              defaultText={t('component.uploader.search')}
              dragDrop={true}
              getUploadProgress={getUploadProgress}
              isPrivate={false} //TODO: revisar si va a ser privado o no
              maxSize={10}
              name={'uploader_1'}
              onChange={handleOnChange}
              uploadedFileText={textProp1}
              url={process.env.REACT_APP_UPLOADER_HOST + 'file/upload'}
              validFormats={['.csv', '.xlsx', '.xls', '.ods']}
            />
            {props.noDataError && 
              <Typography 
                className="m-0"
                variant="caption"
                color='red'
              >
                * {t('stepByStep.massive.step1.noDataInExcel')}
              </Typography>
            }
          </Card>
        </Grid>
      }

      <Grid item xs={6} xl={3} className="d-flex">
        <Card className="d-flex flex-column flex p-3">
          <Typography variant="h6" component="h4" color="text.light">
            {t('stepByStep.massive.step1.newSpreadsheet')}
          </Typography>
          <Divider variant="middle" className="mr-0 ml-0 mt-2 mb-2" />
          <Typography
            variant="caption"
            component="h6"
            color="text.light"
            className="mb-2">
            {t('stepByStep.massive.step1.newSpreadsheetSubtitle')}
          </Typography>
          <Link
            component="button"
            onClick={() => history.push(ASSETS_MANUAL_LOAD)}>
            <Icon sx={{ fontSize: 90 }} className="align-self-center">
              add_circle
            </Icon>
          </Link>
        </Card>
      </Grid>
      {!props.hideUploadExcel && 
        <>
          <Grid item xs={12} className="mt-3">
            <span className="d-flex flex-row align-center mb-2">
              <Typography variant="body2" component="h3" color="text.primary">
                {t('stepByStep.massive.step1.downloadFile')}
              </Typography>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="ml-1"
                color="#61B15A"
                onClick={showNewFileTooltip}
              />
              <Popover
                id={NewFileTooltipId}
                open={newFileTooltip}
                anchorEl={newFileTooltip}
                onClose={closeNewFileTooltip}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}>
                <Card className="p-2 d-flex flex-column">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="mb-1"
                    color="#61B15A"
                  />
                  <Typography variant="body2" component="p">
                    {t('stepByStep.massive.step1.downloadFileHelpi')}
                  </Typography>
                  <div className="d-flex mt-2 justify-end">
                    <Button variant="outlined" onClick={closeNewFileTooltip}>
                      {t('component.understood')}
                    </Button>
                  </div>
                </Card>
              </Popover>
            </span>
            <Button
              variant="contained"
              onClick={() => {
                const link = document.createElement('a');
                link.href = EXCEL_ALTA_MASIVA.default
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
              startIcon={<FontAwesomeIcon icon={faDownload} />}>
              {t('stepByStep.massive.button.download')}
            </Button>
          </Grid>
          <Grid item xs={12} className="mt-3">
            <Typography
              variant="body2"
              component="h3"
              color="text.light"
              className="d-flex mb-2">
              {t('stepByStep.massive.step1.formatFile')} &nbsp;
              <Typography variant="body2" component="h3" color="primary">
                {t('stepByStep.massive.step1.formatFileType')}
              </Typography>
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
}

export default LoadFile;
