import { ASSET_TYPES, EVENT_TYPES } from "../constants";

export const isSpecialField = (selectedAsset, eventType, name) => {
  const allowedAssetIds = Object.values(ASSET_TYPES);
  const allowedEventTypes = Object.values(EVENT_TYPES);
  
  const isAssetAllowed = allowedAssetIds.includes(selectedAsset.id);
  const isEventTypeAllowed = allowedEventTypes.includes(eventType);
  const isSpecialName = name === 'co2_neutralized';

  return isSpecialName && isAssetAllowed && isEventTypeAllowed;
};
