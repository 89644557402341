import React, { useEffect, useState } from 'react';
import { useHistory, useLocation  } from 'react-router-dom';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { EVENTS } from '../../constants/paths';
import ModalErrorInfo from '../../Components/Feature/ModalErrorInfo';
import { useTranslation } from 'react-i18next'
import { Button, Grid, Stack, Step, StepLabel, Stepper } from '@mui/material';
import EventsAll from './EventsAll';
import { putObjectsMassive } from '../../services/object';
import GridMassiveEdit from '../../Components/FileUploader/GridMassiveEdit';
import MassiveSummary from '../../Components/FileUploader/MassiveSummary';
import { calculateLabelDates } from '../../Utils/gridDefaultValues';
import { getAssetsByBox } from '../../services/boxes';
import EventsDelete from './EventsDelete';
import ActivosBasicTable from '../../Layout/components/Activos/ActivosBasicTable';
import ModalErrorResponse from '../../Components/Feature/ModalErrorResponse';
import addMassiveItemsCondition from '../../Helpers/addMassiveItemsCondition';
import { ApiFetch } from '../../Utils/ApiFetch';
import BasicFormStepByStep from '../../Components/Generic/basicFormStepByStep';
import { toast, ToastContainer } from 'material-react-toastify';

const MassiveEvents = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const globalContext = useGlobalContext();
  const { selectedBox, selectedAsset, getSessionDataByKey, userData } = globalContext;
  const location = useLocation();
  const source = location.state?.source;
  const [activeStep, setActiveStep] = React.useState(0);
  const [event, setEvent] = useState()
  const [assets, setAssets] = useState()
  const [steps, setSteps] = useState()
  const [selectedAssets, setSelectedAssets] = useState([])
  const [dataList, setDataList] = useState()
  const timeZone = userData?.userData?.timezone;
  const [modalErrorInfo, setModalErrorInfo] = useState(false);
  const [listErrors, setListErrors] = useState([]);
  const [randomKeyContext, setRandomKeyContext] = useState(0);
  const [temporalIds, setTemporalIds] = useState([]);
  const [availableGeolocations, setAvailableGeolocations] = useState([])
  const [eventFields, setEventFields] = useState([])
  const [eventSteps, setEventSteps] = useState([])
  const models = getSessionDataByKey('models')[selectedAsset.id]
  
  useEffect(() => {
    let stepsTemp = [];
    if (event?.form != null) {
      for (let step of event.form.config) {
        stepsTemp.push(step.title);
      }
    }
    setEventSteps(stepsTemp)
    setSteps(event?.modelSBSOnEvent ? [
      t("stepByStep.massive.spreadsheet.eventStep.selectEvent"),
      t("stepByStep.massive.spreadsheet.eventStep.selectAssets"),
      ...stepsTemp
    ] : [
      t("stepByStep.massive.spreadsheet.eventStep.selectEvent"),
      t("stepByStep.massive.spreadsheet.eventStep.selectAssets"),
      t("stepByStep.massive.spreadsheet.eventStep.loadData"),
      t("stepByStep.massive.spreadsheet.eventStep.manualSummary")
    ])
  }, [event, t])

  useEffect(() => {
    getAssetsByBox(
      selectedBox.id,
      selectedAsset.id,
      models.eventInAssetTransferred ? true : false
    )
    .then(res => setAssets(res.data))
    .catch(err => console.log('error', err))
  }, [selectedAsset, selectedBox])

  useEffect(() => {
    setRandomKeyContext(new Date().valueOf());
  }, []);

  function getRedirectPath(source) {
    return source === 'logicalDeletedAssets' ? '/logicalDeletedAssets' : EVENTS;
  }

  const onModalErrorInfoClosed = () => {
    let redirectPath = "";
    if (props.redirectOnErrorPath) {
      redirectPath = props.redirectOnErrorPath
    } else {
      redirectPath = EVENTS
    }

    history.push({
      pathname: redirectPath,
      state: {},
    });
    setModalErrorInfo(false);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancel = () => {
    history.goBack()
  };

  const onSelectEvent = (event) => {
    if(event.eventType == 2 && globalContext.selectedAsset.id == '35'){
      ApiFetch(
        `boxes/${selectedBox.id}/positionGeometry`,
        globalContext,
        true,
        async (result) => {
          if (result.status === 200) {
            let resJsonTemp = await result.json();
            setAvailableGeolocations(resJsonTemp)
          }
        },
        (error) => {
          console.error(error)
        }
      );
    };

    let attribute = models.attributes
    const eventFieldsTemp = event.fields.map(field => {
      return attribute.find(att => att.name === field.name)
    })

    setEventFields(eventFieldsTemp.filter(e=>e))
    setEvent(event)
    handleNext()
  };

  const handleSubmitMassiveEdit = (list) => {
    setDataList(list);
    handleNext()
  }

  const handleDataChange = (dataList, _dataPairColumns) => {
    setAssets(dataList);
  };

  const handleFinalSubmit = () => {
    let dataListTemp = [];
    dataList.forEach(data => {
      let dataTemp = { ...data };
      delete dataTemp.id;
      let dataTempNoValuesFilter = {};
      for (let key of Object.entries(dataTemp)) {
        if (dataTemp[key[0]] != null && ( event.fields.some(field => field.name === key[0]) || event.eventType === 0 )) {
          dataTempNoValuesFilter[key[0]] = dataTemp[key[0]];
        }
      }
      dataListTemp.push({ ...dataTempNoValuesFilter, pk: data.id });
    });

    let attr =
      models.attributes;

    let dataListTempLabels = [];
    dataListTemp.forEach((value, index) => {
      let labelsTemp = {};
      Object.entries(value).forEach((it) => {
        const rowAttr = attr.find(
          (x) =>
            x.name === it[0] &&
            (x.type === 'select' || x.type === 'date' || x.type === 'dateTime' || x.type === 'multiselect')
        );
        if (rowAttr) {
          if (rowAttr.type === 'select') {
            let selectedItem = rowAttr.values.find((i) => i.key === it[1]);
            if (selectedItem) {
              labelsTemp[rowAttr.name] = selectedItem.label;
            }
          }
          if (rowAttr.type === 'multiselect') {
            let selectedItemLabels = [];
            it[1].split(',').forEach((i) => {
              let item = rowAttr.values.find((x) => x.key === i);
              if (item) {
                selectedItemLabels.push(item.label);
              }
            });
            labelsTemp[rowAttr.name] = selectedItemLabels.join(',');
          }
          if (rowAttr.type === 'date' || rowAttr.type === 'dateTime') {
            const { dateLabel, dateValue } = calculateLabelDates(
              it[1],
              timeZone
            );
            labelsTemp[rowAttr.name] = dateLabel;
            dataListTemp[index][it[0]] = dateValue;
          }
        }
        if (it[0] === '_eventDate_') {
          const { dateLabel, dateValue } = calculateLabelDates(it[1], timeZone);
          labelsTemp[it[0]] = dateLabel;
          dataListTemp[index][it[0]] = dateValue;
        }
      });
      dataListTempLabels.push(labelsTemp);
    });

    const temporalFiltered = temporalIds.filter(asset => selectedAssets.some(selectAsset => selectAsset.id === asset.assetId))

    let dataObj = {
      values: dataListTemp,
      labels: dataListTempLabels,
      headerValues: {},
      action: 'update',
      pk: null,
      objectType: 'asset',
      pkObjectType: selectedAsset.id,
      pkReferer: null,
      holder: selectedBox.id,
      temporalDetailParentId: temporalFiltered.length > 0 ? temporalFiltered : -1,
      globalModel: selectedAsset.id,
      actionContext: { context: 'massive_manual_form', eventType: event.eventType },
    };

    let updateAlotItems = addMassiveItemsCondition(dataObj)
    const redirectPath = getRedirectPath(source);

    putObjectsMassive(dataObj)
    .then(res => {

      !updateAlotItems &&
        history.push({
          pathname: redirectPath,
          state: {
            toast: {
              mode: 'update',
              action: 'updated_massive',
            },
          },
        });

    })
    .catch(err => {
      if (err.response && err.response.data) {
        const errorData = err.response.data;
        console.log("ERROR", err);
        setListErrors(errorData)
        setModalErrorInfo(true)
      } else {
        console.log("ERROR", err);
        setModalErrorInfo(true)
      }
    });

    if(updateAlotItems) history.push({
      pathname: redirectPath,
      state: {
        modal:{
          asyncModal: true,
          mode: 'update',
          action: 'updated_massive',
          message: t('breadcrumb.asset.msg.wait_for_massive_update')
        }
      },   
    })

  };

  const getEventComponent = (source, onSelectEvent) => {
    return source === 'logicalDeletedAssets'
     ? <EventsDelete onSelect={onSelectEvent} />
      : <EventsAll onSelect={onSelectEvent}/>;
  };

  return (
    <Stack spacing={2}>
      <ToastContainer />
      {listErrors.length > 0 ? (
          <ModalErrorResponse
            open={modalErrorInfo}
            toggleModal={() => {
              onModalErrorInfoClosed();
            }}
            modalText={t('component.comErrorList')}
            listErrors={listErrors}
          />
        ) : (
          <ModalErrorInfo
            open={modalErrorInfo}
            toggleModal={() => {
              onModalErrorInfoClosed();
            }}
            modalText={t("component.comError")}
          />
      )}

      <Stepper activeStep={activeStep}>
        {steps?.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 0 && getEventComponent(source, onSelectEvent)}

      {activeStep === 1 &&
        <Grid item xs={12}>
          <ActivosBasicTable
            event={event}
            preSelectedRows={[]}
            sessionContext={randomKeyContext}
            onSelectedRowsChange={(ret) => {
              const selected = assets?.filter(asset => ret.includes(asset.id_asset))
              selected?.forEach(item => Object.assign(item, { id: item.id_asset }).id_asset)
              setSelectedAssets(selected)
            }}
            exportConfig={{
              mode: false,
            }}
            exportFilterAction={(ret) => {
              if (true) {
                setAssets(ret.rows);
              }
            }}
          />
        </Grid>
      }

      {!event?.modelSBSOnEvent ?
        (<>
          {activeStep === 2 &&
            <GridMassiveEdit
              mode={'Edit'}
              data={selectedAssets}
              entityMode={'asset'}
              enableEventDate={true}
              eventType={event.eventType}
              pairColumns={[]}
              excelUploadId={-1}
              filterAttributeModel={event.fields}
              onSubmit={handleSubmitMassiveEdit}
              onSetAmountErrorsInitial={(err) => { console.log(err) }}
              globalModel={selectedAsset.id}
              pkObjectType={selectedAsset.id}
              onDataChange={handleDataChange}
              manualUpload={true}
              handleCancel={handleBack}
              setTemporalIds={setTemporalIds}
              temporalIds={temporalIds}
              availableGeolocations={availableGeolocations}
            />
          }

          {activeStep === 3 && (
            <MassiveSummary
              showControls={true}
              dataList={dataList}
              globalModel={selectedAsset.id}
              onFinalSubmit={handleFinalSubmit}
              amountErrorsInitial={0}
              handleBack={handleBack}
              handleBack={handleBack}
        />
          )}
        </>) : ( <>
          {(activeStep >= 2 && activeStep <= (eventSteps.length + 1)) &&
            <BasicFormStepByStep
              formMode={'vertical'}
              saveFunc={() => { }}
              show={true}
              sectionAccordionMode={false}
              onCanceled={() => {
                handleBack()
              }}
              onUpdated={() => {
                setActiveStep(0)
                toast.success(t('updatedRowsAmount', { amount: selectedAssets.length }), {
                  className: 'toast-message-body',
                  progressClassName: 'toast-message-progress',
                  position: 'bottom-left',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }}
              noSteps={true}
              withDefault={false}
              bindWithDefault={true}
              mode={'update'}
              pk={null}
              pkObjectType={selectedAsset.id}
              pkReferer={null}
              globalModel={selectedAsset.id}
              objectType={'asset'}
              objectTypeBindForm={'asset'}
              parentType={'box'}
              parentTypeSubType={selectedBox.subtype}
              parentId={selectedBox.id}
              fields={eventFields}
              showCancelButton={true}
              cancelButtontext={t('stepByStep.button.cancel')}
              setParentActiveStep={(step)=>{setActiveStep(step + 2)}}
              form={event.form}
              assetSelectedToEvent={selectedAssets}
              eventType={event.eventType}
            />
          }</>
        )
      }

      {activeStep !== 0 && activeStep !== 2 && activeStep !== 3 &&
        <Stack direction={'row'} spacing={6} justifyContent={'center'}>
          <Button variant='outlined' onClick={handleBack}>{t('stepByStep.button.back')}</Button>
          <Button variant='contained'onClick={handleNext} disabled={activeStep === 1 && (!selectedAssets || selectedAssets.length === 0)}>
            {t('stepByStep.button.next')}
          </Button>
        </Stack>
      }

      {activeStep === 0 &&
        <Stack direction={'row'} spacing={6} justifyContent={'center'}>
          <Button variant='outlined' onClick={handleCancel}>{t('stepByStep.button.cancel')}</Button>
        </Stack>
      }

    </Stack>
  );
};

export default MassiveEvents;
