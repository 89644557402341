import React from "react";
import { Card, Grid, Typography, Button } from "@mui/material";

const DelegationMassiveUserList = ({ userList, roles, setUserList, t }) => {
  return userList.length > 0 ? (
    userList.map((user, index) => (
      <Card key={user.id} style={{ marginBottom: 8, padding: "8px" }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={6}>
            <Typography>{user.email}</Typography>
          </Grid>
          <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
            {roles.map((role) => (
              <Button
                key={role.key}
                size="small"
                variant={user.id_template === role.key ? "contained" : "outlined"}
                onClick={() => {
                  setUserList((prevList) =>
                    prevList.map((u, i) => (i === index ? { ...u, id_template: role.key, rol: role.label } : u))
                  );
                }}
              >
                {t(role.label)}
              </Button>
            ))}
          </Grid>
        </Grid>
      </Card>
    ))
  ) : (
    <Typography variant="subtitle1" color="textSecondary">
      {t("component.roles.noUsersAvailable")}
    </Typography>
  );
};

export default DelegationMassiveUserList;
