import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import BasicFormStepByStep from '../../Components/Generic/basicFormStepByStep';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { COMPANY_ALL } from '../../constants/paths';
import { useTranslation } from 'react-i18next';
import modelSelection from '../../Helpers/modelSelection';
import { getSupplyChainFromSubdomain } from '../../Helpers/subdomain';

function CompanyNew(props) {
  const { t } = useTranslation()
  const { userData, refreshUserData } = useGlobalContext();
  const boxContext = userData.userData.userData.box_context;
  let history = useHistory();
  const globalContext = useGlobalContext();
  const [fields, setFields] = useState([]);
  const [form, setForm] = useState(null);
  const [userId, setUserId] = useState(-1);
  const [filterFields, setFilterFields] = useState({})
  const [bindValues, setBindValues] = useState(null);
  const refererContext = {
    pk: null,
    mode: 'new',
    objectTypeSubType: boxContext.objectTypeSubType,
    globalModel: boxContext.model,
    parentType: boxContext.parentType,
    parentTypeSubType: boxContext.parentTypeSubType,
    parentId: boxContext.parentId,
  }
  const currentSupplyChain = getSupplyChainFromSubdomain();

  useEffect(() => {
    setModels();
    let userIdData = props.location && props.location.state && props.location.state.userId;
    if (userIdData === undefined) {
      // TODO: el llamado al global context debería realizarse acá.
      userIdData = userData.userData.id;
    }
    setUserId(userIdData);
  }, []);

  const setModels = (cadenas, pais, roles) => {
    let models = globalContext.getSessionDataByKey('models');
    let attributesModel = []
    let activeModel = {}

    let modelKeyCountry = "DEFAULT"
    let modelLabels = "DEFAULT"
    let modelKeyChain = ""
    let modelKeyRol = ""

    activeModel = models["companyModels"]["basic"]["companyTypes"]["DEFAULT"]

    if(pais){
      modelKeyCountry = models["companyModels"]["basic"]["companyTypes"][pais] ? pais : "AR"
      modelLabels = models["companyModels"]["basic"]["fieldsLabels"][pais] ? pais : "DEFAULT"
    }

    if (cadenas && roles) {
      let model = []

      const chainSort = cadenas?.split(",")
      chainSort.sort((a,b) => b - a)

      const rolSort = roles?.split(",")
      rolSort.sort((a,b) => b - a)

      model = models["companyModels"]["basic"]["companyTypes"][modelKeyCountry]
      modelKeyChain = modelSelection(model, chainSort)

      if(models["companyModels"]["basic"]["companyTypes"][modelKeyCountry][modelKeyChain]){
        model = models["companyModels"]["basic"]["companyTypes"][modelKeyCountry][modelKeyChain]
        modelKeyRol = modelSelection(model, rolSort)
      }

      if(modelKeyChain && modelKeyRol){
        activeModel = models["companyModels"]["basic"]["companyTypes"][modelKeyCountry][modelKeyChain][modelKeyRol]
      }
    }

    for(const step of activeModel.form.config){
      for(const section of step.sections){
        for(const field of section.fields){
          const attribute = models["companyModels"]["basic"].attributes.filter((atrr) => atrr.name === field).map(attr => ({ ...attr }));
          attribute && attributesModel.push(...attribute)
        }
      }
    }

    if (models["companyModels"]["basic"]["fieldsLabels"][modelLabels]) {
      for (const objLabels of models["companyModels"]["basic"]["fieldsLabels"][modelLabels]) {
        const matchingAttribute = attributesModel.find(atrr => atrr.name === objLabels.name);
        matchingAttribute && (matchingAttribute.label = objLabels.label);
      }
    }
    const filteredAttributesModel = attributesModel.map(attr => {
      if (attr.name === "suplychain" && Array.isArray(attr.values)) {
        const filteredValues = attr.values.filter(value => value.key === currentSupplyChain);
        return {
          ...attr,
          values: filteredValues.length > 0 ? filteredValues : attr.values
        };
      }
      return attr;
    });
    setFields(filteredAttributesModel && filteredAttributesModel.length > 0 ? filteredAttributesModel : attributesModel);
    setForm(activeModel.form);
    return models;
  };

  const redirectCompanyAll = async () => {
    await refreshUserData();
    history.push({ pathname: COMPANY_ALL });
  };

  const onChangeField = (field, value, fullValues) => {
    const fieldsChanged = field === "suplychain" ? {...fullValues, rol: ''} : fullValues

    if( field === "l1" || field === "suplychain" || field === "rol"){
      setFilterFields({suplychain: fullValues.suplychain, country: fullValues.l1})
      setBindValues(fieldsChanged)
      setModels(fieldsChanged.suplychain, fieldsChanged.l1, fieldsChanged.rol)
    }
  }

  return (
    <div>
      { /* JSON.stringify(refererContext) */}
      <BasicFormStepByStep
        formMode={'vertical'}
        saveFunc={() => { }}
        show={true}
        showStepTitle={true}
        showSectionTitle={true}
        sectionAccordionMode={false}
        bindWithDefault={false}
        redirectOnErrorPath={COMPANY_ALL}
        autocompleteSandboxMode={false}
        fieldColSize={12}
        onCanceled={() => { redirectCompanyAll() }}
        onUpdated={() => { redirectCompanyAll() }}
        onChangeField={(field, value, fullValues) => {
          onChangeField(field, value, fullValues);
        }}
        fieldsCustomFilterRule={{
          'rol': {
            'conditionValue':
            [filterFields.suplychain, filterFields.country],
            'conditionField': ['fk', 'countries', 'fk_collection'],
            'func': (conditionValue, values) => {
              let flagFk = false;
              let flagCountries = false;
              let flagFkCollection = false;

              if (conditionValue[0] == null && values[0] == null) {
                flagFk = true;
              }
              if (conditionValue[0] != null && values[0] != null) {
                if (conditionValue[0].split(",").includes(values[0])) {
                  flagFk = true;
                }
              }
              if (conditionValue[1] != null && values[1] != null) {
                if (values[1].includes(conditionValue[1])) {
                  flagCountries = true;
                }
              }
              if (values[2] === null) {
                flagFkCollection = true;
              }

              return flagFk && flagCountries && flagFkCollection;
            }
          }
        }}
        bindValues={bindValues}
        withDefault={false}
        mode={refererContext.mode}
        pk={refererContext.pk}
        //pkObjectType={1}
        pkObjectType={refererContext.objectTypeSubType}
        pkReferer={null}
        //globalModel={0}
        //globalModel={refererContext.globalModel} // salir luego de un combo, si no lo tiene va 0
        objectType={'company'}
        objectTypeBindForm={'company'}
        parentType={'root'}
        parentTypeSubType={1}
        parentId={userId}
        count={0}
        fields={fields}
        form={form}
        showCancelButton={true}
        cancelButtontext={t("stepByStep.button.company.cancel")}
        submitButtonText={t("stepByStep.button.company.submit")}
        submitOnlyLastStep={true}
      />
    </div>
  );
}

export default CompanyNew;
