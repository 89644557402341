import React, { useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import {
  Grid,
  Button,
  Typography,
  Card,
  MenuItem,
  Stepper,
  Step,
  Divider,
  StepButton,
  InputAdornment,
  TextField,
  InputLabel,
  Select,
  FormControl,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { getCompanies } from '../../services/sideBar';
import { getCompaniesRoles } from '../../services/companies';
import { assignCompanyDelegate, getUserByEmail, getUsersByCompanyId } from '../../services/users';
import { createCompanyInvitation } from '../../services/invitation';
import { getSupplyChainFromSubdomain } from '../../Helpers/subdomain';
import useGlobalContext from '../../Hooks/useGlobalContext';
import DelegationErrorAlerts from './Components/DelegationErrorAlerts';
import DelegationMassiveUserList from './Components/DelegationMassiveUserList';

const delegationTypes = [
  { labelKey: "component.roles.userDelegation", value: "user", isMassive: false },
  { labelKey: "component.roles.userCompanyGroupDelegation", value: "userGroup", isMassive: true },
];

function UserDelegationsCompanyStepper(props) {
  const { t } = useTranslation();
  const globalContext = useGlobalContext();
  const [step, handleStep] = React.useState(0);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [roles, setRoles] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [selectedRol, setSelectedRol] = React.useState(null);
  const [userFound, setUserFound] = React.useState(null);
  const [email, setEmail] = React.useState("");
  const [showNotFound, setShowNotFound] = React.useState(false);
  const [assignError, setAssignError] = React.useState(false);
  const [assignWarning, setAssignWarning] = React.useState(false);
  const [inviteError, setInviteError] = React.useState(false);
  const [isMassiveDelegation, setIsMassiveDelegation] = React.useState(false);
  const [failedUsers, setFailedUsers] = React.useState([]);
  const [massiveAssignError, setMassiveAssignError] = React.useState(false);
  const [userList, setUserList] = React.useState([]);
  const [selectedTargetCompany, setSelectedTargetCompany] = React.useState(null);
  const currentSupplyChain = getSupplyChainFromSubdomain();

  useEffect(() => {
    getCompanies()
      .then(res => {
        if (res.status === 200) {
          let data = res.data;
          
          const filteredCompanies = currentSupplyChain
            ? data.filter(company => company.suplychain === currentSupplyChain)
            : data;
  
          setCompanies(filteredCompanies);
        }
      })
      .catch(err => console.log('error getCompanies', err));
  }, []);

  useEffect(() => {
    getCompaniesRoles(selectedCompany)
      .then(res => {
        if (res.data.length > 0) {
          let dataTemp = [];
          for (let item of res.data) {
            let itemTemp = {
              key: item.id,
              label: item.name
            };
            dataTemp.push(itemTemp);
          }
          setRoles(dataTemp);
        }
      })
      .catch(err => console.log('error getCompaniesRoles', err));
  }, [selectedCompany]);

  const searchBoxEmail = () => {
    setShowNotFound(false);
    setUserFound(null);
    setInviteError(false);
    getUserByEmail(email)
      .then(res => {
        if (res.data.length > 0) {
          setUserFound(res.data[0]);
          setShowNotFound(false);
        } else {
          setUserFound(null);
          setShowNotFound(true);
        }
      })
      .catch(err => console.log('error searchFromEmail', err));
  };

  const inviteUser = async () => {
    try {
      const res = await createCompanyInvitation({
        email,
        company_id: selectedCompany,
        permission_template_id: selectedRol,
      });
      props.showModal(res.data.message);
      if (props.handleAsign) {
        props.handleAsign();
      }
    } catch (error) {
      setInviteError(true);
      console.error('Error invitando al usuario', error);
    }
  };

  const asignRol = async (userEmail = email, id_template = selectedRol, box = selectedCompany) => {
    try {
      const res = await assignCompanyDelegate(userEmail, id_template, box);
      if (props.handleAsign) {
        props.handleAsign();
      }
    } catch (err) {
      if (!isMassiveDelegation) {
        if (err.response && err.response.status === 403) {
          setAssignWarning(true);
        } else {
          setAssignError(true);
        }
      }
      console.error('error users/delegations/company/asign', err);
      throw err;
    }
  };  

  const handleChangeDelegation = (event) => {
    const { value } = event.target;
    const selectedOption = delegationTypes.find((type) => type.value === value);

    if (selectedOption) {
      setIsMassiveDelegation(selectedOption.isMassive);
    } else {
      setIsMassiveDelegation(false);
    }
  };

  const assignRolesToUsers = async () => {
    try {
      const validUsers = userList.filter(user => user.id_template && user.rol);

      if (validUsers.length === 0) {
        console.warn("No hay usuarios válidos para asignar roles.");
        return;
      }
  
      const failed = [];
  
      const promises = validUsers.map(async (user) => {
        try {
          await asignRol(user.email, user.id_template, selectedTargetCompany);
        } catch (error) {
          console.error('Error asignando rol a ' + user.email, error);
          failed.push(user);
        }
      });
  
      await Promise.all(promises);
  
      if (failed.length > 0) {
        setFailedUsers(failed);
        setMassiveAssignError(true);
      } else {
        props.showModal("Los roles fueron asignados exitosamente.");
        props.onCancel();
        setUserList([]);
      }
    } catch (error) {
      console.error("Error en la asignación masiva:", error);
      setMassiveAssignError(true);
    }
  };
    
    useEffect(() => {
      if (isMassiveDelegation && selectedCompany) {
        getUsersByCompanyId(selectedCompany, globalContext)
          .then(users => {
            setUserList(users.map(user => ({
              id: user.id,
              email: user.email,
              id_template: null,
              rol: null
            })));
          })
          .catch(err => console.log("Error obteniendo usuarios de la empresa:", err));
      }
    }, [selectedCompany, isMassiveDelegation]); 

    useEffect(() => {
      if (props.companyId) {
        setSelectedTargetCompany(props.companyId)
      }
    }, [props.companyId])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="mt-0">
          <Grid item xs={12} className="mt-2">
          <Stepper nonLinear activeStep={step}>
            <Step key={0}>
              <StepButton color="inherit">
                {t("component.roles.step.selectDelegation")}
              </StepButton>
            </Step>
            <Step key={1}>
              <StepButton color="inherit">
                {!isMassiveDelegation 
                  ? t("component.roles.step.selectUser") 
                  : t("component.roles.step.selectTargetCompany")}
              </StepButton>
            </Step>
            <Step key={2}>
              <StepButton color="inherit">
                {!isMassiveDelegation 
                  ? t("component.roles.step.selectCompany") 
                  : t("component.roles.step.selectSourceCompany")}
              </StepButton>
            </Step>
            <Step key={3}>
              <StepButton color="inherit">
                {!isMassiveDelegation 
                  ? t("component.roles.step.selectRole") 
                  : t("component.roles.step.selectUsersAndRoles")}
              </StepButton>
            </Step>
          </Stepper>
          </Grid>
          <Divider variant="middle" className="mt-4" style={{ marginBottom: '5px' }} />
        </Grid>
        {step === 0 && (
          <Grid container display="flex" className="p-2" alignItems="center" justifyContent="center">
            <FormControl fullWidth>
              <InputLabel size={"small"}>{t("component.roles.step.typeOfDelegation")}</InputLabel>
              <Select
               size={"small"}
               value={isMassiveDelegation ? "userGroup" : "user"}
               onChange={handleChangeDelegation}
               id="select"
               placeholder={t("component.roles.step.typeOfDelegation")}>
               {delegationTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {t(type.labelKey)}
                </MenuItem>
               ))}
              </Select>
            </FormControl>
            <div style={{ alignSelf: 'flex-end', position: 'absolute', bottom: 35 }}>
              <Button onClick={() => props.onCancel()} sx={{ mr: 1, mt: 3 }} variant="outlined">
                {t('stepByStep.button.cancel')}
              </Button>
              <Button onClick={() => handleStep(1)} sx={{ mr: 1, mt: 3 }} variant="contained">
                {t('stepByStep.button.next')}
              </Button>
            </div>
          </Grid>
        )}

        {(step === 1) &&
          <>
            <Grid container display="flex" className="p-2" alignItems="center" justifyContent="center">
             {!isMassiveDelegation ? (
              <>
                <Grid item xs={12} className="mt-3" alignItems="center" justifyContent="center">
                  <Typography variant="subtitle1" component="p">
                    {t("component.roles.user")}
                  </Typography>
                  <div className="d-flex align-center mt-2">
                    <TextField
                      name="user"
                      size="medium"
                      style={{ width: "100%" }}
                      value={email}
                      onChange={(val) => { setEmail(val.target.value); }}
                      placeholder={t("component.roles.user")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FontAwesomeIcon icon={faSearch} color="#61B15A" />
                          </InputAdornment>
                        ),
                      }}
                    />&nbsp;
                    <Button onClick={searchBoxEmail} variant="contained">
                      {t('stepByStep.button.search')}
                    </Button>
                  </div>
                </Grid>
                {(showNotFound) &&
                  <div className="d-flex align-center mt-2" style={{ color: "orange" }}>
                    {t("component.roles.noEmailFound")}
                  </div>
                }
                <>
                  {userFound !== null &&
                    <Grid item xs={12} className="mt-3">
                      <Grid component={Card} container>
                        <Grid item xs={9} className="p-4">
                          <Grid container>
                            <Grid item xs={4}>
                              <Typography variant="body1" component="p" color="text.primary" className="mb-2">
                                {t("component.roles.firstName")}
                              </Typography>
                              <Typography variant="body1" component="p" color="text.primary" className="mb-2">
                                {t("component.roles.lastName")}
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography variant="body1" component="p" className="mb-2">
                                <b>{userFound?.firstname}</b>
                              </Typography>
                              <Typography variant="body1" component="p" className="mb-2">
                                <b>{userFound?.lastname}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                </>
              </>
              ) : (
              <>
              <FormControl fullWidth>
                <InputLabel size={"small"} id={`demo-controlled-open-select-label`}>
                  {t("component.roles.step.company")}
                </InputLabel>
                <Select
                  size={"small"}
                  label={t("component.roles.step.selectCompany")}
                  labelId={`demo-controlled-open-select-label}`}
                  value={selectedTargetCompany}
                  margin="dence"
                  id="select"
                  placeholder={t("component.roles.step.selectCompany")}
                  onChange={(val) => setSelectedTargetCompany(val.target.value)}>
                  <MenuItem value="">
                    <em>Deseleccionar</em>
                  </MenuItem>
                  {companies.map((item) => <MenuItem key={item.id} value={item.id}>{item.company_name}</MenuItem>)}
                </Select>
              </FormControl>
              </>
              )}
              <div style={{ alignSelf: 'flex-end', position: 'absolute', bottom: 35 }}>
                <Button type="button" onClick={() => { if (props.onCancel) { props.onCancel(); } }} sx={{ mr: 1 }} variant="outlined">
                  {t('stepByStep.button.cancel')}
                </Button>
                <Button type="button" onClick={() => { handleStep(0); }} sx={{ mr: 1 }} variant="outlined">
                  {t('stepByStep.button.back')}
                </Button>
                <Button 
                type="button" 
                disabled={
                  (isMassiveDelegation && !selectedTargetCompany) ||
                  (!isMassiveDelegation && !userFound && !showNotFound)
                }  
                onClick={() => { handleStep(2); }} 
                sx={{ mr: 1 }} 
                variant="contained">
                  {t('stepByStep.button.next')}
                </Button>
              </div>
            </Grid>
          </>
        }
        {(step === 2) &&
          <Grid container display="flex" className="p-2" alignItems="center" justifyContent="center">
            <FormControl fullWidth>
              <InputLabel size={"small"} id={`demo-controlled-open-select-label`}>
                {t("component.roles.step.company")}
              </InputLabel>
              <Select
                size={"small"}
                label={t("component.roles.step.selectCompany")}
                labelId={`demo-controlled-open-select-label}`}
                value={selectedCompany}
                margin="dence"
                id="select"
                placeholder={t("component.roles.step.selectCompany")}
                onChange={(val) => setSelectedCompany(val.target.value)}>
                <MenuItem value="">
                  <em>Deseleccionar</em>
                </MenuItem>
                {companies.map((item) => <MenuItem key={item.id} value={item.id}>{item.company_name}</MenuItem>)}
              </Select>
            </FormControl>
            <div style={{ alignSelf: 'flex-end', position: 'absolute', bottom: 35 }}>
              <Button type="button" onClick={() => { if (props.onCancel) { props.onCancel(); } }} sx={{ mr: 1, mt: 3 }} variant="outlined">
                {t('stepByStep.button.cancel')}
              </Button>
              <Button type="button" onClick={() => { handleStep(1); }} sx={{ mr: 1, mt: 3 }} variant="outlined">
                {t('stepByStep.button.back')}
              </Button>
              <Button 
              type="button"
              disabled={
                (isMassiveDelegation && !userList) ||
                (!isMassiveDelegation && (selectedCompany === null || selectedCompany === ""))
              }
              sx={{ mr: 1, mt: 3 }} 
              variant="contained" 
              onClick={() => { handleStep(3); }}>
                {t('stepByStep.button.next')}
              </Button>
            </div>
          </Grid>
        }
        
        {(step === 3) &&
          <Grid container display="flex" alignItems="center" justifyContent="center" className="p-2">
            <Grid item xs={12}>
            {!isMassiveDelegation ? (
              <FormControl fullWidth>
                <InputLabel size={"small"}>{t("component.roles.step.selectRole")}</InputLabel>
                <Select
                  size={"small"}
                  value={selectedRol}
                  onChange={(e) => setSelectedRol(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Deseleccionar</em>
                  </MenuItem>
                  {roles.map(role => (
                    <MenuItem key={role.key} value={role.key}>{role.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <DelegationMassiveUserList userList={userList} roles={roles} setUserList={setUserList} t={t} />
            )}
          </Grid>
          <Grid sx={{ 
            display: "flex", 
            flexDirection: "column", 
            justifyContent: "center", 
            alignItems: "center", 
          }}>
            <DelegationErrorAlerts 
              assignError={assignError} 
              assignWarning={assignWarning} 
              inviteError={inviteError} 
              massiveAssignError={massiveAssignError} 
              failedUsers={failedUsers} 
              t={t} 
            />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type="button" onClick={() => { if (props.onCancel) { props.onCancel(); setAssignError(false); setAssignWarning(false); } }} sx={{ mr: 1, mt: 3 }} variant="outlined">
                {t('stepByStep.button.cancel')}
              </Button>
              <Button type="button" onClick={() => { setAssignError(false); setAssignWarning(false); handleStep(2); }} sx={{ mr: 1, mt: 3 }} variant="outlined">
                {t('stepByStep.button.back')}
              </Button>
              <Button type="button" disabled={isMassiveDelegation ? !userList.some(user => user.id_template) : !selectedRol} sx={{ mr: 1, mt: 3 }} variant="contained" onClick={isMassiveDelegation ? assignRolesToUsers : (showNotFound ? inviteUser : () => asignRol())}>
                {showNotFound ? t('stepByStep.button.invite') : t('stepByStep.button.assign')}
              </Button>
            </div>
            </Grid>
          </Grid>
        }

      </Grid>
    </>
  );
}

export default UserDelegationsCompanyStepper;