import React, { useEffect, useState } from 'react';
import { Grid, Stepper, Step, StepButton, Divider } from '@mui/material';
import { ApiFetch } from '../../../Utils/ApiFetch';
import { useHistory } from 'react-router-dom';

import LoadFile from '../LoadFile';
import PairColumns from '../PairColumns';
import GridMassiveEdit from '../GridMassiveEdit';
import MassiveSummary from '../MassiveSummary';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { ASSETS } from '../../../constants/paths';
import ModalMessage from '../../../Components/Feature/ModalMessage';
import { useTranslation } from 'react-i18next';
import { calculateLabelDates } from '../../../Utils/gridDefaultValues';

import addMassiveItemsCondition from '../../../Helpers/addMassiveItemsCondition';

const UploadContainer = (props) => {
  const { t } = useTranslation();
  let history = useHistory();

  const steps = [
    t("stepByStep.massive.assetStep.loadFileEvent"),
    t("stepByStep.massive.assetStep.pairColumns"),
    t("stepByStep.massive.assetStep.gridMassiveEdit"),
    t("stepByStep.massive.assetStep.massiveSummary")
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [assets, setAssets] = useState([]);
  const [maxStepAvailable, setMaxStepAvailable] = useState(0);
  const [pairColumns, setPairColumns] = useState([]);
  const [waitModal, setWaitModal] = useState(false);
  const [infoStep, setInfoStep] = useState(['', '', '', '']);
  const [excelUploadId, setExcelUploadId] = useState(-1);
  const [dataList, setDataList] = useState({});
  const [amountErrorsInitial, setAmountErrorsInitial] = useState(null);
  const globalContext = useGlobalContext();
  const { userData } = globalContext;
  const timeZone = userData?.userData?.timezone;
  const [resJson, setResJson] = useState({});
  const [isResUploadOk, setisResUploadOk] = useState(false);
  const [missingColumns, setMissingColumns] = useState([]);
  const [noDataError, setNoDataError] = useState(false);

  useEffect(() => {
    setActiveStep(0);
    updateDataState('', 0);
  }, []);

  const handleStep = (step) => () => {
    if (step < maxStepAvailable) setActiveStep(step);
  };

  const updateDataState = (data, index, index2 = -1) => {
    let newArr = [...infoStep]; // copying the old datas array
    newArr[index] = data;
    if (index2 !== -1) newArr[index2] = data;
    setInfoStep(newArr);
  };

  const handleAllPair = (id) => {
    resetMassiveEditState();

    setExcelUploadId(id);
    setActiveStep(2);
    updateDataState(id, 2);

    setMaxStepAvailable(2);
  };

  const handleSubmit = (list) => {
    setDataList(list);
    setActiveStep(3);
    updateDataState(list, 3);

    setMaxStepAvailable(3);
  };

  const handleAmountErrorsInitial = (val) => {
    if (amountErrorsInitial == null) {
      setAmountErrorsInitial(val);
    }
  };

  const handleDataChange = (dataList, dataPairColumns) => {
    // dataList is the setAssets in the MassiveEdit
    setAssets(dataList);
    setPairColumns(dataPairColumns);
  };

  const resetMassiveEditState = () => {
    setAssets([]);
    setPairColumns([]);
    setMaxStepAvailable(1);
  };

  const handleUploadFile = async(file) => {
    setNoDataError(false)
    const fileInfo = file || -1;
    const postData = {
      fileInfo: fileInfo,
      globalModelId: props.globalModel,
      mode: props.entityMode,
      eventType: props.eventType,
    };

    await ApiFetch(
      'parseExcel/validate',
      globalContext,
      true,
      async (result) => {
        if (result.status === 200) {
          let resJsonTemp = await result.json();

          if(resJsonTemp.specificRows.length > 0) {
            setActiveStep(1);
            updateDataState(file, 1);
        
            setMaxStepAvailable(1);
            setResJson(resJsonTemp);
            setMissingColumns(resJsonTemp?.missingColumns);
            setisResUploadOk(true);
          } else {
            setNoDataError(true)
          }
        }
      },
      (error) => {
        setisResUploadOk(false);
      },
      {
        method: 'POST',
        body: JSON.stringify(postData),
      }
    );
  };

  const handleFinalSubmit = () => {
    let dataListTemp = [];

    if (props.action === 'update') {
      dataList.map((data) => {
        let dataTemp = { ...data };
        delete dataTemp.id;
        let dataTempNoValuesFilter = {};
        for (let key of Object.entries(dataTemp)) {
          if (dataTemp[key[0]] != null) {
            dataTempNoValuesFilter[key[0]] = dataTemp[key[0]];
          }
        }
        dataListTemp.push({ ...dataTempNoValuesFilter, pk: data._pk_ });
      });
    }

    let attr =
      globalContext.getSessionDataByKey('models')[props.globalModel].attributes;
    if (props.action === 'add') {
      dataListTemp = [...dataList];
    }

    let dataListTempLabels = [];
    dataListTemp.map((value, index) => {
      let labelsTemp = {};
      Object.entries(value).map((it) => {
        const rowAttr = attr.find(
          (x) =>
            x.name === it[0] &&
            (x.type === 'select' || x.type === 'date' || x.type === 'dateTime' || x.type === 'multiselect')
        );
        if (rowAttr) {
          if (rowAttr.type === 'select') {
            let selectedItem = rowAttr.values.find((i) => i.key === it[1]);
            if (selectedItem) {
              labelsTemp[rowAttr.name] = selectedItem.label;
            }
          }
          if (rowAttr.type === 'multiselect') {
            let selectedItemLabels = [];
            it[1].split(',').map((i) => {
              let item = rowAttr.values.find((x) => x.key === i);
              if (item) {
                selectedItemLabels.push(item.label);
              }
            });
            labelsTemp[rowAttr.name] = selectedItemLabels.join(',');
          }
          if (rowAttr.type === 'date' || rowAttr.type === 'dateTime') {
            const { dateLabel, dateValue } = calculateLabelDates(it[1], timeZone);
            labelsTemp[rowAttr.name] = dateLabel;
            dataListTemp[index][it[0]] = dateValue;
          }
        }
        // TODO: fix provisorio agregar _event_date_ al modelo para poder controlar mejor las fechas
        if (it[0] === '_eventDate_') {
          const { dateLabel, dateValue } = calculateLabelDates(it[1], timeZone);
          labelsTemp[it[0]] = dateLabel;
          dataListTemp[index][it[0]] = dateValue;
        }
      });
      dataListTempLabels.push(labelsTemp);
    });

    let dataObj = {
      values: dataListTemp,
      labels: dataListTempLabels,
      headerValues: {
        //date:eventDate,
        //comment:eventComment
      },
      action: props.action,
      pk: props.pk,
      objectType: props.objectType, //"asset",
      pkObjectType: props.pkObjectType, //assetContext.objectTypeSubType,
      pkReferer: props.pkReferer,
      holder: props.parentId, //assetContext.parentId,
      temporalDetailParentId: props.temporalDetailParentId, // -1
      globalModel: props.globalModel, //assetContext.model,
      actionContext: { context: 'massive_file_form', eventType: -1 }
    };

    let addAlotItems = addMassiveItemsCondition(dataObj)

    if(!addAlotItems) setWaitModal(true)

    ApiFetch(
      'objects/add/massive',
      globalContext,
      true,
      !addAlotItems ? 
      async (res) => {
        if (res.status === 200) {
          props.onUploadOk(res);
          history.push({
            pathname: ASSETS,
            state: {
              toast: {
                mode: 'new',
                action: 'updated_massive',
              },
            },
          });
        } else {
          props.onUploadError(res);
          setWaitModal(false);
        }
      } :
        ()=>{
        }
      ,
      !addAlotItems ? 
      (error) => {
        props.onUploadError(error);
      } : ()=>{},
      {
        method: 'POST',
        body: JSON.stringify(dataObj),
      }
    );

    if(addAlotItems){
      history.push({
        pathname: ASSETS,
        state: {
          modal: {
            asyncModal: true,
            mode: 'new',
            action: 'updated_massive',
            message: t('breadcrumb.asset.msg.wait_for_massive_update')
          },            
      }});
    }

  }

  return (

    <Grid container spacing={2}>
      {!props.hideUploadExcel &&
        <Grid item xs={12} className="mt-0">
          <Grid item xs={12} className="mt-2">
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={index < maxStepAvailable}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Divider
            variant="middle"
            className="mt-4"
            style={{ marginBottom: '5px' }}
          />
        </Grid>
      }

      {(activeStep === 0) && (
        <LoadFile noDataError={noDataError} onUploadFile={handleUploadFile} {...props}></LoadFile>
      )}
      {activeStep === 1 && (
        <PairColumns
          onAllPair={handleAllPair}
          resJson={resJson}
          isResUploadOk={isResUploadOk} 
          missingColumns={missingColumns}
          setMissingColumns={setMissingColumns}
        />
      )}
      {activeStep === 2 && (
        <GridMassiveEdit
          data={assets}
          mode={props.action === 'add' ? 'Add' : 'Edit'}
          manualUpload={true}
          entityMode={props.entityMode}
          eventType={props.eventType}
          pairColumns={pairColumns}
          pkObjectType={props.pkObjectType}
          globalModel={props.globalModel}
          excelUploadId={excelUploadId}
          onDataChange={handleDataChange}
          onSubmit={handleSubmit}
          onSetAmountErrorsInitial={
            handleAmountErrorsInitial
          }
        />
      )}
      {activeStep === 3 && (
        <MassiveSummary
          showControls={true}
          dataList={dataList}
          globalModel={props.globalModel}
          onFinalSubmit={handleFinalSubmit}
          amountErrorsInitial={amountErrorsInitial}></MassiveSummary>
      )}

      <ModalMessage
        open={waitModal}
        toggleModal={() => { }}
        modalText={t('component.processing')}
      />

    </Grid>
  );
};

export default UploadContainer;
