import { OpenStreetMapProvider } from 'leaflet-geosearch';

const parseToGeoJSON = (coords) => {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [coords[1], coords[0]],
    },
    properties: {
      center: {
        lat: coords[0],
        lng: coords[1],
      },
    },
  };
};

export const geocodeAddress = async (fieldsValues, attributes) => {
  let countries = attributes.find((a) => a.name == 'l1').values;
  let states = attributes.find((a) => a.name == 'l2').values;
  let country = countries?.find((a) => a.key == fieldsValues.l1)?.label;
  let state = states?.find((a) => a.key == fieldsValues.l2)?.label;
  const provider = new OpenStreetMapProvider();
  let presicion =
    fieldsValues.zip_code &&
    fieldsValues.address_number &&
    fieldsValues.address_street;
  const query = `${
    presicion
      ? fieldsValues.address_number + ' ' + fieldsValues.address_street + ', '
      : ''
  }${fieldsValues.l3}, ${
    presicion ? fieldsValues.zip_code : state
  }, ${country}`;
  const results = await provider.search({ query });

  if (results && results.length > 0) {
    const { x: lon, y: lat } = results[0];
    return parseToGeoJSON([lat, lon]);
  }
};
