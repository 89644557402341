import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    textAlign: 'right',
    marginRight: theme.spacing(3),
  },
}));

const Version = () => {
  const classes = useStyles();
  const versionNumber = process.env.REACT_APP_VERSION || "0.0.0";

  return (
    <Box className={classes.root}>
      <p>v{versionNumber}</p>
    </Box>
  );
};

export default Version;
