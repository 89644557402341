import Timeline from '@mui/lab/Timeline';
import { TimeLineCard } from './TimeLineCard';
import { TimeLineClientCard } from './TimeLineClientCard';

export const TimeLine = ({ information, tt, logoWoText}) => {

  let clients = information.filter(a => a.tipo == "Cliente Destino")
  return (
    <Timeline className='pr-0 pl-0'>
      {clients.length > 0 && <TimeLineClientCard tt={tt} information={clients} logoWoText={logoWoText}/>}
      {information.filter(a => a.tipo != "Cliente Destino").map((tl, index) => 
        <TimeLineCard key={index} tt={tt} information={tl} logoWoText={logoWoText}/>
      )}      
    </Timeline>
  );
};
