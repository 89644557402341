import React from "react";
import { Alert, Grid, Typography } from "@mui/material";

const DelegationErrorAlerts = ({ assignError, assignWarning, inviteError, massiveAssignError, failedUsers, t }) => {
  return (
    <Grid sx={{marginTop: '1rem'}}>
      {assignError && <Alert severity="error">{t("component.roles.delegationError")}</Alert>}
      {assignWarning && <Alert severity="warning">{t("component.roles.userInvalid")}</Alert>}
      {inviteError && <Alert severity="error">{t("component.roles.inviteError")}</Alert>}
      {massiveAssignError && failedUsers.length > 0 && (
        <Alert severity="error">
          <Typography>{t("component.roles.massiveAssignError")}</Typography>
          <ul>{failedUsers.map((user) => <li key={user.email}>{user.email}</li>)}</ul>
        </Alert>
      )}
    </Grid>
  );
};

export default DelegationErrorAlerts;
