import { ApiFetch } from "../Utils/ApiFetch";

export const fetchCompanyDelegatedUsers = (companyId, globalContext) => {
  return new Promise((resolve) => {
    ApiFetch(
      `users/delegations/company/${companyId}`,
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          let data = await res.json();
          resolve(data);
        } else {
          resolve([]);
        }
      },
      (error) => {
        console.error("Error en users/delegations/company/:", error);
        resolve([]);
      },
      {
        method: 'GET',
      }
    );
  });
};

export const fetchBoxDelegatedUsers = (boxId, globalContext) => {
  return new Promise((resolve) => {
    ApiFetch(
      `users/delegations/box/${boxId}`,
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          let data = await res.json();
          resolve(data);
        } else {
          resolve([]);
        }
      },
      (error) => {
        console.error("Error en users/delegations/box/:", error);
        resolve([]);
      },
      {
        method: 'GET',
      }
    );
  });
};
