import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';

const styles = {
  large: {
    width: '625px',
    display: 'inline-block',
    padding: '10px',
    border: '1px solid black',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    textAlign: 'center',
    fontSize: '20px',
  },
  small: {
    width: '245px',
    display: 'inline-block',
    padding: '10px',
    border: '1px solid black',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    textAlign: 'center',
    fontSize: '20px',
  },
};

const OrgChart = ({ delegation, users }) => {
  const entityType = delegation.type === "COMPANY" ? "(Compañía)" : "(Establecimiento)";

  const getDisplayName = (firstname, lastname, email) => {
    if (firstname?.trim() || lastname?.trim()) {
      return (
        <>
          {firstname} <br /> {lastname}
        </>
      );
    }
    return email;
  };

  return (
    <Tree 
      lineWidth={'2px'} 
      lineColor={'#3969E8'}
      label={<div style={styles.large}>{delegation.name} <br /> <small>{entityType}</small></div>}
    >
      <TreeNode label={<div style={styles.large}>{delegation.user_owner_name} <br /> <small>(Owner)</small></div>}>
        {users.map(user => (
          <TreeNode 
            key={user.id} 
            label={
              <div style={styles.small}>
                {getDisplayName(user.firstname, user.lastname, user.email)}
                <br />
                <small>({user.rol})</small>
              </div>
            } 
          />
        ))}
      </TreeNode>
    </Tree>
  );
};

export default OrgChart;
