import React from 'react';
import styled from 'styled-components';
import { FormHelperText } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

const StyledFormHelperText = styled(FormHelperText)`
color: red !important;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
margin-right: 0.5rem;
`;

function ErrorField(props) {
  const { t } = useTranslation();
  const { errors } = props;
  return (
    <>
      {errors.map((messages) => (
        <StyledFormHelperText>
          <StyledFontAwesomeIcon icon={faExclamationTriangle} />
          {                
            `stepByStep.error.${messages.toLowerCase()}` 
            !== t(`stepByStep.error.${messages.toLowerCase()}`)
            ? t(`stepByStep.error.${messages.toLowerCase()}`)
            : messages
          }
        </StyledFormHelperText>
      ))}
    </>
  );
}

export default ErrorField;
