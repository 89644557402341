import { isEmpty } from '@origino-io/fieldvalidations';
import React from 'react';
import TextMaskedField from './TextMaskedField';

function NumberMaskedField({
  disabled,
  error,
  label,
  min,
  max,
  scale,
  mask,
  name,
  onChange,
  value,
  thousandsSeparator,
  padFractionalZeros,
  normalizeZeros,
  radix,
  mapToRadix,
  size,
  sx,
  isAssetCountField,
  assetsSelectedCount
}) {
  const onAccept = (value, mask) => {
    onChange(name, value);
    mask.updateValue(mask.value);
  };

  const defaultProps = {
    disabled: disabled,
    error: error,
    label: label,
    min: min || Number.MIN_SAFE_INTEGER,
    max: max || Number.MAX_SAFE_INTEGER,
    scale: scale,
    mask: mask,
    name: name,
    sx: sx,
    onAccept: onAccept,
    value: isAssetCountField ? assetsSelectedCount : value,
    size: size,
  };

  if (!isEmpty(value)) {
    defaultProps['InputLabelProps'] = { shrink: true };
  }

  if (thousandsSeparator !== undefined) {
    defaultProps['thousandsSeparator'] = thousandsSeparator;
  }

  if (padFractionalZeros !== undefined) {
    defaultProps['padFractionalZeros'] = padFractionalZeros;
  }

  if (normalizeZeros !== undefined) {
    defaultProps['normalizeZeros'] = normalizeZeros;
  }

  if (radix !== undefined) {
    defaultProps['radix'] = radix;
  }

  if (mapToRadix !== undefined) {
    defaultProps['mapToRadix'] = mapToRadix;
  }

  return <TextMaskedField {...defaultProps} />;
}

export default NumberMaskedField;
