import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiFetch } from '../../Utils/ApiFetch';
import useGlobalContext from '../../Hooks/useGlobalContext';

import {
  Alert,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  Autocomplete,
  Typography,
  CircularProgress,
} from '@mui/material';

import { USER_DELEGATIONS_ALL, USER_DELEGATIONS_COMPANY_ALL } from '../../constants/paths';

import UserDelegationsStepper from './UserDelegationsStepper';
import UserDelegationsCompanyStepper from './UserDelegationsCompanyStepper';
import DelegationAccordion from './Components/DelegationAccordion';
import ConfirmDialog from '../../Components/Feature/ConfirmDialog';

import { useTranslation } from 'react-i18next'
import { getDelegationsMade } from '../../services/users';
import ModalMessage from '../../Components/Feature/ModalMessage';
import { getSupplyChainFromSubdomain } from '../../Helpers/subdomain';

import useDelegationsFilter from '../../Hooks/useDelegationsFilter';
import { exportToPdfDelegations } from '../../Helpers/pdfExport';

// MAIN COMPONENT

function UserDelegations() {
  const { t } = useTranslation()
  let history = useHistory();

  const globalContext = useGlobalContext();
  const { calculateActualQuota, selectedCompany } = globalContext;

  const [delegationsMade, setDelegationsMade] = useState([]);
  const [delegationsReceived, setDelegationsReceived] = useState([]);

  const [dialog, setDialog] = useState(false);
  const [dialogCompany, setDialogCompany] = useState(false);

  const [alertInfo, setAlertInfo] = useState({
    show: false,
    alertText: '',
  });

  const optionsDelegation = [ {id: 1, name: 'Recibidas'}, {id: 2, name: 'Realizadas'}]

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dataDelegation, setDataDelegation] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const [modalText, setModalText] = useState(''); 
  const [suplychainValue, setSuplychainValue] = useState(null);
  const [activeValue, setActiveValue] = useState(null);
  const [companyValue, setCompanyValue] = useState(null)
  const [establishmentValue, setEstablishmentValue] = useState(null)
  const [userValue, setUserValue] = useState(null)
  const [delegationTypeValue, setDelegationTypeValue] = useState(null)
  const [isExporting, setIsExporting] = useState(false);


  const [filters, setFilter] = useState({
    suplychain: null,
    active: null,
    company: null,
    establishment: null,
    users: null,
    delegationType: null
  })

  const currentSupplyChain = getSupplyChainFromSubdomain();

  const {
    filteredCompanyDelegations,
    filteredBoxDelegations,
    filterSuplychain,
    filterActive,
    filterCompanies,
    filterEstablishments,
    filterUsers
  } = useDelegationsFilter(delegationsMade, delegationsReceived, filters);
 
  useEffect(() => {
    getAllDelegationsMade();
    getAllDelegationsReceived();
    loadQuotaModal();
  }, []);

  const getAllDelegationsMade = () => {
    getDelegationsMade()
      .then(res => {
        if (res.status === 200) {
          let data = res.data;

          const filteredDelegations = currentSupplyChain
            ? data.filter(delegation => delegation.suplychain === currentSupplyChain)
            : data;

          const sortedDelegations = filteredDelegations.sort((a, b) => a.name.localeCompare(b.name));
          setDelegationsMade(sortedDelegations);
        }
      })
      .catch(err => console.log('error getUserDelegations', err))
  };

  const getAllDelegationsReceived = () => {
    ApiFetch(
      'users/delegations/received/all',
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          let data = await res.json();

          const filteredDelegations = currentSupplyChain
            ? data.filter(delegation => delegation.suplychain === currentSupplyChain)
            : data;

          const sortedDelegations = filteredDelegations.sort((a, b) => a.name.localeCompare(b.name));
          setDelegationsReceived(sortedDelegations);
        }
      },
      (error) => {
        console.log('error /users/delegations/received/all', error);
      },
      {
        method: 'GET',
      }
    );
  };

  const openModalCompany = () => setDialogCompany(true)

  const openModalEstablishment = () => setDialog(true)

  const showModal = (message) => {
    setModalText(message);
    setOpenModal(true);
  };

  const onClickBoxDelegationsMade = (box) => {
    history.push({
      pathname: USER_DELEGATIONS_ALL,
      state: {
        boxDelegate: box
      }
    });
  };

  const onClickCompanyDelegationsMade = (company) => {
    history.push({
      pathname: USER_DELEGATIONS_COMPANY_ALL,
      state: {
        companyDelegate: company
      }
    });
  };

// DELEGATIONS RECEIVED

  const deleteBoxDelegation = (data) => {
    const payload = {
      user: data.id_user,
      rol: data.id_template,
      box: data.id_entity
    };

    ApiFetch(
      'users/delegations/deleteBoxAsign',
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          const newDelegations = delegationsReceived.filter(d => d.id_entity !== data.id_entity);
          setDelegationsReceived(newDelegations);
        }
        else{
          console.log("Delete error: ",res);
        }
      },
      (error) => {
        console.log('error users/delegations/deleteBoxAsign', error);
      },
      {
        method: 'POST',
        body: JSON.stringify(payload)
      }
    );
  };

  const deleteCompanyDelegation = (data) => {
    const payload = {
      user: data.id_user,
      rol: data.id_template,
      company: data.id_entity
    };

    ApiFetch(
      'users/delegations/deleteCompanyAsign',
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          const newDelegations = delegationsReceived.filter(d => d.id_entity !== data.id_entity);
          setDelegationsReceived(newDelegations);
        }
        else{
          console.log("Delete error: ",res);
        }
      },
      (error) => {
        console.log('error users/delegations/deleteCompanyAsign', error);
      },
      {
        method: 'POST',
        body: JSON.stringify(payload)
      }
    );
  };

  const applyFilter = () => {
    setFilter({    
      suplychain: suplychainValue,
      active: activeValue,
      company: companyValue,
      establishment: establishmentValue,
      users: userValue,
      delegationType: delegationTypeValue
    })
  }

  const cleanFilter = () => {
    setUserValue(null)
    setCompanyValue(null)
    setEstablishmentValue(null)
    setActiveValue(null)
    setSuplychainValue(null)
    setDelegationTypeValue(null)

    setFilter({    
      suplychain: null,
      active: null,
      company: null,
      establishment: null,
      users: null,
      delegationType: null
    })
  }

  const handleOnConfirm = () => {
    if (dataDelegation?.type === "BOX" && dataDelegation?.id_entity > 0)
      deleteBoxDelegation(dataDelegation);

    if (dataDelegation?.type === "COMPANY" && dataDelegation?.id_entity > 0)
      deleteCompanyDelegation(dataDelegation);
  }

  const handleOpenConfirm = (data) => {
    setDataDelegation(data);
    setConfirmOpen(true);
  }


// LOAD QUOTA MODAL

  const loadQuotaModal = async () => {
    const { remainingQuota, companiesOwnerLength } = await calculateActualQuota({
      id: selectedCompany.id,
      type: 'companyDelegations'
    });
    if (remainingQuota <= 0 && companiesOwnerLength > 0) {
      setAlertInfo((current) => ({
        ...current,
        show: true,
        alertText: t('quotaExceeded', { type: t('companyDelegations') }),
      }));
    }
  };

  const handleExportToPDF = async () => {
    setIsExporting(true)
  
    try {
      await exportToPdfDelegations(filteredCompanyDelegations, filteredBoxDelegations, globalContext);
    } catch (error) {
      console.error("Error exporting PDF:", error);
    } finally {
      setIsExporting(false);
    }
  };
  
  
  return (
    <>
      <Grid container spacing={2}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'space-around',
            gap: 2,
            ml: 2,
            mt: 2
          }}
        >
          <Box 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: 2,
              borderRadius: 3,
              padding: 2,
              boxShadow: "1"
            }}
          >
            <Typography
              variant='h6'
            >
              {t("stepByStep.msg.filters")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6} lg={4}>
                <Autocomplete
                  options={filterSuplychain}
                  getOptionLabel={(option) => option.name}
                  value={filterSuplychain.find((option) => option.id === suplychainValue) || null}
                  onChange={(e, newValue) => {
                    setSuplychainValue(newValue ? newValue.id : null);
                    setActiveValue(null);
                    setCompanyValue(null);
                    setEstablishmentValue(null);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={t("stepByStep.msg.suplychain")} size="small" />
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <Autocomplete
                  options={suplychainValue ? filterActive.filter((option) => option.fk === suplychainValue) : []}
                  getOptionLabel={(option) => option.name}
                  value={filterActive.find((option) => option.id === activeValue) || null}
                  onChange={(e, newValue) => {
                    setActiveValue(newValue ? newValue.id : null);
                    setCompanyValue(null);
                    setEstablishmentValue(null);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={t("stepByStep.msg.active")} size="small" />
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <Autocomplete
                  options={suplychainValue && activeValue ? filterCompanies.filter((option) => option.fk.includes(suplychainValue)) : []}
                  getOptionLabel={(option) => option.name}
                  value={filterCompanies.find((option) => option.id === companyValue) || null}
                  onChange={(e, newValue) => {
                    setCompanyValue(newValue ? newValue.id : null);
                    setEstablishmentValue(null);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={t("stepByStep.msg.company")} size="small" />
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <Autocomplete
                  options={
                    companyValue && activeValue
                      ? filterEstablishments.filter((option) => option.fkType === activeValue && option.fkcompanyId == companyValue)
                      : []
                  }
                  getOptionLabel={(option) => option.name}
                  value={filterEstablishments.find((option) => option.id === establishmentValue) || null}
                  onChange={(e, newValue) => {
                    setEstablishmentValue(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={t("stepByStep.msg.establishment")} size="small" />
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <Autocomplete
                  options={filterUsers}
                  getOptionLabel={(option) => option.name}
                  value={filterUsers.find((option) => option.id === userValue) || null}
                  onChange={(e, newValue) => {
                    setUserValue(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={t("stepByStep.msg.user")} size="small" />
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <Autocomplete
                  options={optionsDelegation}
                  getOptionLabel={(option) => option.name}
                  value={optionsDelegation.find((option) => option.id === delegationTypeValue)}
                  onChange={(e, newValue) => {
                    setDelegationTypeValue(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={t("stepByStep.msg.delegationType")} size="small" />
                  )}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: 2
              }}
            >
              <Button
                className="ml-2"
                variant="contained"
                sx={{
                  minHeight: 40,
                  width: 140
                }}
                onClick={cleanFilter}
              >
                {t("stepByStep.msg.clean")}
              </Button>
              <Button
                className="ml-2"
                variant="contained"
                sx={{ 
                  minHeight: 40,
                  width: 140
                }}
                onClick={applyFilter}
              >
                {t("stepByStep.msg.filter")}
              </Button>
              <Button
                className="ml-2"
                variant="contained"
                sx={{ 
                  minHeight: 40,
                  width: 140
                }}
                disabled={isExporting} 
                onClick={handleExportToPDF}
              >
                {isExporting ? <CircularProgress size={24} color="inherit" /> : t("stepByStep.msg.exportPDF")}
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 2,
              borderRadius: 3,
              padding: 2,
              boxShadow: "1"
            }}
          >
            <Typography
              variant='h6'
              sx={{
                mb: 2
              }}
            >
              {t("stepByStep.msg.delegation")}
            </Typography>
            <Box sx={{display: 'flex'}}>
              <Button
                disabled={alertInfo.show}
                className="ml-2"
                variant="contained"
                onClick={openModalCompany}>
                {t("stepByStep.button.user.assignRoleCompany")}
              </Button>
              <Button
                className="ml-2"
                variant="contained"
                onClick={openModalEstablishment}>
                {t("stepByStep.button.user.assignRole")}
              </Button>
            </Box>
          </Box>
        </Box>

        {(!filters.delegationType || filters.delegationType === 1) &&
          <>
            <Grid item xs={12} className="mb-2">
              <h4>{t("stepByStep.msg.delegationsMade")}</h4>
            </Grid>
            <Grid item xs={12} className="mb-2">
              <DelegationAccordion
                ariaControls={"panel1b-content"}
                id={"panel1b-header"}
                title={t('company')}
                expanded={true}
                content={           
                  delegationsMade.filter((delegation) => 
                    delegation.type === "COMPANY" && (
                      (filters.company && filters.company === delegation.id)
                      ||
                      (!filters.company && filters.suplychain 
                        && delegation.relation_data.some(data => data.suplychainId === filters.suplychain)
                      )
                      ||
                      (!filters.suplychain && !filters.company)
                    ) && (
                      filters.users === delegation.user_owner_id || !filters.users
                    )
                  )
                }
                onClick={onClickCompanyDelegationsMade}
                open={true}
              />
            </Grid>

            <Grid item xs={12} className="mb-2">
              <DelegationAccordion
                ariaControls={"panel1b-content"}
                id={"panel1b-header"}
                title={t('establishment')}
                expanded={true}
                content={
                  delegationsMade.filter(
                    (delegation) => delegation.type === "BOX" && (
                      (filters.establishment && filters.establishment === delegation.id)
                      ||
                      (!filters.establishment && filters.company && filters.company === delegation.relation_data[0]?.companyId)
                      ||
                      (!filters.establishment && !filters.company && filters.suplychain 
                        && delegation.relation_data[0]?.suplychainId === filters.suplychain
                      )
                      ||
                      (!filters.establishment && !filters.suplychain && !filters.company)
                    ) && (
                      filters.users === delegation.user_owner_id || !filters.users
                    )
                  )
                }
                onClick={onClickBoxDelegationsMade}
                open={true}
                xs={12}
              />
            </Grid>
          </>
        }
              
        {alertInfo.show && (
          <Grid item xs={12} className="mb-2">
            <span className="d-flex flex-row align-center">
              <Alert severity="error">
                <div dangerouslySetInnerHTML={{ __html: alertInfo.alertText }}></div>
              </Alert>
            </span>
          </Grid>
        )}

        {(!filters.delegationType || filters.delegationType === 2) &&
          <>
            <Grid item xs={12} className="mb-2">
              <h4>{t("stepByStep.msg.delegationsReceived")}</h4>
            </Grid>
            <Grid item xs={12} className="mb-2">
              <DelegationAccordion
                ariaControls={"panel1b-content"}
                id={"panel1b-header"}
                title={t('company')}
                expanded={true}
                content={delegationsReceived.filter(
                  (delegation) => (delegation.type === "COMPANY" && delegation.origin === 'COMPANY_DELEGATE') && (
                    (filters.company && filters.company === delegation.id_entity)
                    ||
                    (!filters.company && filters.suplychain 
                      && delegation.relation_data.some(data => data.suplychainId === filters.suplychain)
                    )
                    ||
                    (!filters.suplychain && !filters.company)
                  ) && (
                    filters.users === delegation.delegated_by_user_id || !filters.users
                  )
                )}
                onClick={handleOpenConfirm}
                open={true}
              />
            </Grid>

            <Grid item xs={12} className="mb-2">
              <DelegationAccordion
                ariaControls={"panel2b-content"}
                id={"panel2b-header"}
                title={t('establishment')}
                expanded={true}
                content={delegationsReceived.filter(
                  (delegation) => (delegation.type === "BOX" && delegation.origin === 'COMPANY_DELEGATE') && (
                    (filters.establishment && filters.establishment === delegation.id_entity)
                    ||
                    (!filters.establishment && filters.company && filters.company === delegation.relation_data[0]?.companyId)
                    ||
                    (!filters.establishment && !filters.company && filters.suplychain 
                      && delegation.relation_data[0]?.suplychainId === filters.suplychain
                    )
                    ||
                    (!filters.establishment && !filters.suplychain && !filters.company)
                  ) && (
                    filters.users === delegation.delegated_by_user_id || !filters.users
                  ))}
                onClick={handleOpenConfirm}
                open={true}
              />
            </Grid>
          </>
        }
      </Grid>

      <>
        <Dialog maxWidth="xl" open={dialog} onClose={() => { }}>
          <DialogTitle>{t("component.roles.title")}</DialogTitle>
          <DialogContent style={{ minHeight: "500px", minWidth: "800px", maxWidth: "800px" }}>
            <UserDelegationsStepper
              onCancel={() => {
                setDialog(false);
              }}
              handleAsign={() => {
                setDialog(false);
              }}
              showModal={showModal}
            />
          </DialogContent>
        </Dialog>

        <Dialog maxWidth="xl" open={dialogCompany} onClose={() => { setDialogCompany(false); }}>
          <DialogTitle>{t("component.roles.title")}</DialogTitle>
          <DialogContent style={{ minHeight: "500px", minWidth: "800px", maxWidth: "800px" }}>
            <UserDelegationsCompanyStepper
              onCancel={() => {
                setDialogCompany(false);
              }}
              handleAsign={() => {
                setDialogCompany(false);
              }}
              showModal={showModal}
            />
          </DialogContent>
        </Dialog>
      </>

      <>
        <ConfirmDialog
          title={t("stepByStep.msg.deleteDelegation")}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleOnConfirm}
          onClose={()=>{}}
        >
          {t("stepByStep.msg.deleteDelegationConfirmMsg")}
        </ConfirmDialog>
      </>

      <ModalMessage open={openModal} toggleModal={setOpenModal} modalText={modalText} />
    </>
  );
}

export default UserDelegations;
