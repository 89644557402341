import React from 'react';
import SidebarTop from './SidebarTop';
import SidebarMenu from './SidebarMenu';
import SidebarUser from './SidebarUser';
import { Box } from '@mui/material';

 
function Sidebar() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'space-around',
      height: 'inherit',
      justifyContent: 'space-between'
    }} >
      <Box>
        <SidebarTop />
        <SidebarMenu />
      </Box>
      <SidebarUser />
    </div>
  );
}

export default Sidebar;