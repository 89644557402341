import { ImageListItem } from '@mui/material';
import { useTheme } from '@mui/styles';
import Carousel from 'react-material-ui-carousel'

export const PhotoCarrousel = ({ photos }) => {
  const theme = useTheme();

  if (!Array.isArray(photos)) {
    photos = [photos]
  }

  return (
    <Carousel 
      swipe
      navButtonsAlwaysVisible={false}
      navButtonsProps={{ 
        style: {
          backgroundColor: theme?.palette?.text?.primary ?? "grey",
          opacity: 0.5,
          color: theme?.palette?.background?.default ?? 'black'
        }
      }}
      indicatorIconButtonProps={{
        style: {
          color: theme?.palette?.text?.secondary
        }
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: theme?.palette?.text?.primary
        }
      }}
    >
      {photos.map((photo, index) => (
        <ImageListItem
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            height:'360px',
            overflow:'hidden'
          }}
        >
          <img
            src={photo}
            style={{
              height:'360px',
              objectFit:'contain'
            }}
            alt={'ImageList img'}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </Carousel>
  );
};
